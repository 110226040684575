import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import type {
  detailData,
  DetailSectionData,
  DetailSectionDataItem,
} from '@domgen/dgx-fe-base-components';
import {
  BasketItemPaymentOption,
  CheckoutPlanDetails,
  PaymentType,
} from '@common/util-models';
import { DatePipe, DecimalPipe } from '@angular/common';
import { DateWithOrdinalPipe } from '@common/util-foundation';
import { QuoteDocument } from '@domgen/dgx-fe-business-models';

@Component({
  selector: 'wl-appliance-plan-detail',
  templateUrl: './appliance-plan-detail.component.html',
  styleUrls: ['./appliance-plan-detail.component.scss'],
  providers: [DateWithOrdinalPipe, DecimalPipe],
})
export class AppliancePlanDetailComponent implements OnChanges {
  @Input() planDetails: CheckoutPlanDetails | undefined | null;
  @Input() multiPlanDetails: CheckoutPlanDetails[] | undefined | null;
  @Input() isInsurance: boolean | null = false;
  @Input() isHeating: boolean | null = false;
  @Input() productInfo: QuoteDocument | undefined = undefined;
  @Input() terms: QuoteDocument | undefined = undefined;
  @Output() termsClick: EventEmitter<void> = new EventEmitter();

  detail: detailData[] = [];
  brand = '';
  boilerType = '';
  multiDetails: DetailSectionData[] = [];
  multiDetailsFooter: DetailSectionDataItem | null = {} as DetailSectionDataItem;
  multiPaymentDetails: detailData[] = [];
  multiDetailsTotalPayment = 0;

  constructor(
    private _decimalPipe: DecimalPipe,
    private _datePipe: DatePipe,
    private datePipe: DateWithOrdinalPipe
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.planDetails && !changes.multiPlanDetails) {
      return;
    }

    if (this.planDetails && !this.multiPlanDetails) {
      this.mapToDetailArray(this.planDetails);
    }
    if (this.multiPlanDetails) {
      this.mapMultiDetails(this.multiPlanDetails);
    }
  }

  mapToDetailArray(planDetails: CheckoutPlanDetails) {
    if (planDetails?.applianceDetails?.applianceName) {
      this.boilerType = planDetails.applianceDetails.applianceName;
    }
    if (planDetails?.applianceDetails?.brandName) {
      this.brand = planDetails.applianceDetails.brandName;
    }

    this.detail = [
      {
        key: `Your ${this.isInsurance ? 'policy' : 'plan'} number`,
        value: planDetails?.confirmation?.planNumber,
      },
    ];
    const paymentOption = planDetails?.paymentOption;
    if (
      paymentOption &&
      paymentOption.paymentType === PaymentType.DirectDebit
    ) {
      const value = this.addMonthlyPayment(paymentOption);
      this.detail.push({
        key: 'Monthly payments',
        value,
      });

      this.detail.push({
        key: 'Payment method',
        value: 'Direct debit',
      });

      if (this.planDetails?.paymentDay) {
        const day = this.getDaySuffix(this.planDetails.paymentDay);
        const company = this.isInsurance
          ? ' Domestic & General Insurance PLC'
          : 'Domestic & General Services Limited';

        const monthCountText =
          paymentOption?.numPayments === 0 ? '' : 'for a total of 12 months';

        this.detail.push({
          key: 'Monthly payment date',
          value: `The ${day} of every month ${monthCountText} to ${company}.<br/><br/>If your chosen payment date is within the next 14 days, your first and second payments will be taken at the same time next month.`,
        });
      }
    } else if (paymentOption) {
      this.detail.push({
        key: 'One-time payment',
        value: `One-time payment of £${this._decimalPipe.transform(
          paymentOption.fee,
          '1.2-2'
        )}`,
      });
      this.detail.push({
        key: 'Payment method',
        value: 'Credit or debit card',
      });
    }

    if (planDetails?.confirmation?.breakdownStartDate) {
      const planStartDateObj = {
        key: `${this.isInsurance ? 'Policy' : 'Plan'} starting date`,
        value: this._datePipe.transform(
          planDetails.confirmation.breakdownStartDate,
          'dd/MM/yy'
        ),
      };
      this.detail.splice(2, 0, planStartDateObj);
    }
  }

  mapMultiDetails(multiPlanDetails: CheckoutPlanDetails[]) {
    this.multiDetailsTotalPayment = 0;
    const paymentOption = multiPlanDetails[0]?.paymentOption;

    multiPlanDetails.map((planDetails) => {
      let multiDetailsItem: DetailSectionData = {} as DetailSectionData;
      if (
        planDetails?.applianceDetails?.applianceName &&
        planDetails?.applianceDetails?.brandName
      ) {
        multiDetailsItem = {
          ...multiDetailsItem,
          title: `${planDetails.applianceDetails.brandName} ${planDetails.applianceDetails.applianceName}`,
        };
      }

      if (planDetails?.confirmation?.planNumber) {
        multiDetailsItem = {
          ...multiDetailsItem,
          details: [
            {
              key: `${this.isInsurance ? 'Policy' : 'Plan'} number`,
              value: planDetails.confirmation.planNumber,
            },
          ],
        };
      }

      if (planDetails?.confirmation?.breakdownStartDate) {
        const planStartDateObj = {
          key: `${this.isInsurance ? 'Cover' : 'Plan'} start date`,
          value: this._datePipe.transform(
            planDetails.confirmation.breakdownStartDate,
            'dd/MM/yy'
          ),
        };
        multiDetailsItem = {
          ...multiDetailsItem,
          details: [...multiDetailsItem.details, planStartDateObj],
        };
      }

      if (planDetails.paymentOption) {
        const key = this.addMultiItemMonthlyPayment(planDetails.paymentOption);
        const value =
          planDetails.paymentOption.paymentType === PaymentType.DirectDebit
            ? 'a month'
            : '';
        multiDetailsItem = {
          ...multiDetailsItem,
          aside: {
            key: key,
            value: value,
          },
        };
      }

      this.multiDetails.push(multiDetailsItem);
    });

    if (
      paymentOption &&
      paymentOption.paymentType === PaymentType.DirectDebit
    ) {
      if (!this.isHeating) {
        this.multiPaymentDetails.push({
          key: 'Monthly payments',
          value:
            'All appliance purchases will be billed as separate monthly Direct Debits and will therefore appear separately on your bank statement. This also means you can choose to cancel any of the appliance policies at any time, if you want to.',
        });
      }

      this.multiPaymentDetails.push({
        key: 'Payment method',
        value: 'Direct debit',
      });
      if (multiPlanDetails[0]?.paymentDay) {
        const day = this.getDaySuffix(multiPlanDetails[0].paymentDay);
        const company = this.isInsurance
          ? ' Domestic & General Insurance PLC'
          : 'Domestic & General Services Limited';

        const monthCountText =
          paymentOption?.numPayments === 0 ? '' : 'for a total of 12 months';

        this.multiPaymentDetails.push({
          key: 'Monthly payment date',
          value: `Day ${multiPlanDetails[0].paymentDay} of each month.<br/><br/> The ${day} of every month ${monthCountText} to ${company}.<br/><br/>If your chosen payment date is within the next 14 days, your first and second payments will be taken at the same time next month.`,
        });
      }
    } else if (paymentOption) {

      const payment = this.isHeating ? paymentOption.fee : this.multiDetailsTotalPayment;
      this.multiPaymentDetails.push({
        key: 'One-time payment',
        value: `One-time payment of £${this._decimalPipe.transform(
          payment,
          '1.2-2'
        )}`,
      });

      this.multiPaymentDetails.push({
        key: 'Payment method',
        value: 'Credit or debit card',
      });
    }

    this.addMultiItemTotalMonthlyPaymentDetails(paymentOption);
  }

  getDaySuffix(day: number) {
    const now = new Date();
    const date = new Date(now.getFullYear(), now.getMonth(), day);
    return this.datePipe.transform(date, 'do');
  }

  addMonthlyPayment(paymentOption: BasketItemPaymentOption): string {
    let value = `${
      (paymentOption.numSubsequentPayments || 0) + 1
    } monthly payments of £${this._decimalPipe.transform(
      paymentOption.subsequentPayment,
      '1.2-2'
    )}`;

    if (paymentOption?.numPayments === 0) {
      value = `£${this._decimalPipe.transform(
        paymentOption.subsequentPayment,
        '1.2-2'
      )} a month`;
    } else if (paymentOption.firstPayment !== paymentOption.subsequentPayment) {
      value = `1 payment of £${this._decimalPipe.transform(
        paymentOption.firstPayment,
        '1.2-2'
      )}<br/>then ${
        paymentOption.numSubsequentPayments
      } monthly payments of £${this._decimalPipe.transform(
        paymentOption.subsequentPayment,
        '1.2-2'
      )}`;
    }
    return value;
  }

  addMultiItemMonthlyPayment(paymentOption: BasketItemPaymentOption): string {
    const payment =
      paymentOption.paymentType === PaymentType.DirectDebit
        ? paymentOption.subsequentPayment
        : paymentOption.fee;
    this.multiDetailsTotalPayment += payment;
    return `£${this._decimalPipe.transform(payment, '1.2-2')}`;
  }

  addMultiItemTotalMonthlyPaymentDetails(
    paymentOption: BasketItemPaymentOption | undefined
  ): void {
    if (this.isHeating) {
      this.multiDetailsFooter =
        paymentOption?.paymentType === PaymentType.DirectDebit
          ? {
              key: `${paymentOption.numPayments} monthly payments of`,
              value: `£${this._decimalPipe.transform(
                paymentOption.subsequentPayment,
                '1.2-2'
              )}`,
              subtitle: `Total cost £${this._decimalPipe.transform(
                paymentOption.subsequentPayment * paymentOption.numPayments,
                '1.2-2'
              )}`,
            }
          : null;
    } else {
      this.multiDetailsFooter = {
        key: 'Total',
        value: `£${this._decimalPipe.transform(
          this.multiDetailsTotalPayment,
          '1.2-2'
        )} a month`,
      };
    }
  }
}
