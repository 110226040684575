import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AoOrenAppComponent } from './components/ao-oren-app/ao-oren-app.component';
import { AoOrenFeatureShellRoutingModule } from './ao-oren-feature-shell-router.module';
import { SharedUiBaseComponentsModule } from '@domgen/dgx-fe-base-components';
import { CommonUiSalesComponentsModule } from '@common/ui-sales-components';
import { OrenDataAccessOfferRenewalModule } from '@oren/data-access-offer-renewal';
import {
  HeaderModule,
  SharedAoComponentsModule,
} from '@domgen/dgx-ui-whitelabel-components';
import { META_REDUCERS } from './meta-reducers/meta-reducers';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  CorrelationIdPartialState,
  correlationIdReducer,
  CORRELATION_ID_KEY,
} from '@common/data-access-shared';
import {
  AppProvidersConfig,
  AppProvidersConfigToken,
  CommonFeatureCoreModule,
} from '@common/feature-core';
import { OrenUiComponentsModule } from '@oren/ui-components';
import { environment } from '@ao-oren/util-environment';
import { EffectsModule } from '@ngrx/effects';
import { BuildConfigService, ENVIRONMENT } from '@common/util-foundation';
import { refreshApiConfigToken } from '@shared/feature-auth';
import { OrenDataAccessCheckoutModule } from '@oren/data-access-checkout';
import {
  OfferQueryParamsResolver,
  OfferRenewalGuard,
  OfferRenewalMultiplePaymentOptionsGuard,
  OfferRenewalQueryParamsResolver,
  RenewalQueryParamsResolver,
} from '@oren/feature-shell';

@NgModule({
  imports: [
    CommonModule,
    SharedUiBaseComponentsModule,
    CommonUiSalesComponentsModule,
    AoOrenFeatureShellRoutingModule,
    StoreModule.forRoot(
      {
        [CORRELATION_ID_KEY]: correlationIdReducer,
      } as ActionReducerMap<
        CorrelationIdPartialState & Record<string, unknown>
      >,
      {
        metaReducers: !environment.production
          ? [...META_REDUCERS]
          : [...META_REDUCERS],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    OrenDataAccessCheckoutModule,
    OrenDataAccessOfferRenewalModule,
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    CommonFeatureCoreModule,
    EffectsModule.forRoot(),
    OrenUiComponentsModule,
    SharedAoComponentsModule,
    HttpClientModule,
    HeaderModule,
  ],
  declarations: [AoOrenAppComponent],
  exports: [AoOrenAppComponent],
  providers: [
    {
      provide: ENVIRONMENT,
      useValue: environment,
    },
    {
      provide: refreshApiConfigToken,
      useFactory: (buildConfigService: BuildConfigService) =>
        buildConfigService.config.refreshAPI,
      deps: [BuildConfigService],
    },
    OfferRenewalGuard,
    OfferRenewalMultiplePaymentOptionsGuard,
    OfferRenewalQueryParamsResolver,
    OfferQueryParamsResolver,
    RenewalQueryParamsResolver,
  ],
})
export class AoOrenFeatureShellModule {
  static forRoot(
    navigationConfig: AppProvidersConfig
  ): ModuleWithProviders<AoOrenFeatureShellModule> {
    return {
      ngModule: AoOrenFeatureShellModule,
      providers: [
        { provide: AppProvidersConfigToken, useValue: navigationConfig },
      ],
    };
  }
}
