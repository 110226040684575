import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
} from '@angular/core';
import { ENVIRONMENT } from '@common/util-foundation';
import { Environment } from '@common/util-models';

@Component({
  selector: 'sales-appliance-details-form-header',
  templateUrl: './appliance-details-form-header.component.html',
  styleUrls: ['./appliance-details-form-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplianceDetailsFormHeaderComponent {
  @Input() hasExistingWhiteGoodsApplianceQuote = false;
  @Input() discountPercentage: number | undefined = undefined;
  public isMultiBasket = this.environment.multiBasket;

  constructor(@Inject(ENVIRONMENT) private readonly environment: Environment) {}

  get initialFormHeaderTitle() {
    return this.isMultiBasket
      ? this.discountPercentage
        ? 'Get a discounted quote for up to 10 home appliances'
        : 'Get a quick quote for up to 10 home appliances'
      : "Let's start your quote";
  }
}
