import {
  ContractType,
  CoverType,
  CoverTypeName,
  CreateQuoteResponse,
  GoogleTagConfig,
  ItemType,
  PaymentType,
  Quote,
} from '@common/util-models';

export const MOCK_CREATE_QUOTE_RESPONSE = {
  requestedItemId: '2',
  basket: {
    basketId: '1',
    created: '2020-12-08T10:39:12.022Z',
    updated: '2020-12-08T10:39:12.557Z',
    expiry: '2020-12-15T10:39:13.000Z',
    status: 'ACTIVE',
    requestSource: 'DandGUK',
    items: [
      {
        requestSource: 'DandGUK',
        basketId: '1',
        itemId: '1',
        itemType: 'QUOTE',
        created: '2020-12-08T10:39:12.557Z',
        updated: '2020-12-08T10:39:12.557Z',
        expiry: '2020-12-08T13:27:13.000Z',
        status: 'ACTIVE',
        data: {
          quotes: [
            {
              quoteId: '1',
              contractTypeCode: ContractType.Insurance,
              productType: 'I',
              companyCode: 'D',
              schemeCode: 'VU6',
              isBundle: false,
              paymentTerm: 'IPM',
              coverType: 'STD',
              breakdownStartDate: '2021-01-07',
              periodOfCover: 0,
              waitDays: 30,
              excessAmount: 0,
              paymentOptions: [
                {
                  preferredPayment: true,
                  paymentType: 'D',
                  paymentProfile: 'P',
                  firstPayment: 8,
                  subsequentPayment: 8,
                  numPayments: 11,
                  numSubsequentPayments: 10,
                  paymentFrequency: 1,
                  fee: 96,
                },
              ],
            },
            {
              quoteId: '2',
              coverType: 'STD',
              contractTypeCode: ContractType.Insurance,
              productType: 'I',
              companyCode: 'D',
              schemeCode: 'VU6',
              paymentTerm: 'IPM',
              isBundle: false,
              breakdownStartDate: '2021-01-07',
              periodOfCover: 0,
              waitDays: 30,
              excessAmount: 50,
              paymentOptions: [
                {
                  preferredPayment: true,
                  paymentType: 'D',
                  paymentProfile: 'P',
                  firstPayment: 10,
                  subsequentPayment: 6,
                  numPayments: 11,
                  numSubsequentPayments: 10,
                  paymentFrequency: 1,
                  fee: 96,
                },
              ],
            },
            {
              quoteId: '3',
              coverType: 'STD',
              contractTypeCode: ContractType.Insurance,
              productType: 'I',
              companyCode: 'D',
              schemeCode: 'VU6',
              paymentTerm: 'IPM',
              isBundle: false,
              breakdownStartDate: '2021-01-07',
              periodOfCover: 0,
              waitDays: 30,
              excessAmount: 95,
              paymentOptions: [
                {
                  preferredPayment: true,
                  paymentType: 'D',
                  paymentProfile: 'P',
                  firstPayment: 8,
                  subsequentPayment: 4,
                  numPayments: 11,
                  numSubsequentPayments: 10,
                  paymentFrequency: 1,
                  fee: 96,
                },
              ],
            },
            {
              quoteId: '4',
              contractTypeCode: ContractType.Insurance,
              productType: 'I',
              companyCode: 'D',
              schemeCode: 'VU6',
              paymentTerm: 'IPM',
              coverType: 'G-STD',
              isBundle: false,
              breakdownStartDate: '2021-01-07',
              periodOfCover: 0,
              waitDays: 30,
              excessAmount: 0,
              paymentOptions: [
                {
                  preferredPayment: true,
                  paymentType: 'D',
                  paymentProfile: 'P',
                  firstPayment: 8,
                  subsequentPayment: 8,
                  numPayments: 11,
                  numSubsequentPayments: 10,
                  paymentFrequency: 1,
                  fee: 96,
                },
              ],
            },
            {
              quoteId: '5',
              contractTypeCode: ContractType.Insurance,
              productType: 'I',
              companyCode: 'D',
              schemeCode: 'VU6',
              paymentTerm: 'IPM',
              coverType: 'G-STD',
              isBundle: false,
              breakdownStartDate: '2021-01-07',
              periodOfCover: 0,
              waitDays: 30,
              excessAmount: 10,
              paymentOptions: [
                {
                  preferredPayment: true,
                  paymentType: 'D',
                  paymentProfile: 'P',
                  firstPayment: 8,
                  subsequentPayment: 8,
                  numPayments: 11,
                  numSubsequentPayments: 10,
                  paymentFrequency: 1,
                  fee: 96,
                },
              ],
            },
            {
              quoteId: '6',
              contractTypeCode: ContractType.Insurance,
              productType: 'I',
              companyCode: 'D',
              schemeCode: 'VU6',
              paymentTerm: 'IPM',
              coverType: 'G-STD',
              isBundle: false,
              breakdownStartDate: '2021-01-07',
              periodOfCover: 0,
              waitDays: 30,
              excessAmount: 50,
              paymentOptions: [
                {
                  preferredPayment: true,
                  paymentType: 'C',
                  paymentProfile: 'P',
                  firstPayment: 10,
                  subsequentPayment: 8,
                  numPayments: 11,
                  numSubsequentPayments: 10,
                  paymentFrequency: 1,
                  fee: 120,
                },
              ],
            },
            {
              quoteId: '7',
              contractTypeCode: ContractType.Insurance,
              productType: 'I',
              companyCode: 'D',
              schemeCode: 'VU6',
              paymentTerm: 'IPM',
              coverType: 'G-STD',
              isBundle: false,
              breakdownStartDate: '2021-01-07',
              periodOfCover: 0,
              waitDays: 30,
              excessAmount: 95,
              paymentOptions: [
                {
                  preferredPayment: true,
                  paymentType: 'D',
                  paymentProfile: 'P',
                  firstPayment: 10,
                  subsequentPayment: 6,
                  numPayments: 11,
                  numSubsequentPayments: 10,
                  paymentFrequency: 1,
                  fee: 120,
                },
              ],
            },
            {
              quoteId: '8',
              contractTypeCode: ContractType.Insurance,
              productType: 'I',
              companyCode: 'D',
              schemeCode: 'VU6',
              paymentTerm: 'IPM',
              coverType: 'P-STD',
              isBundle: false,
              breakdownStartDate: '2021-01-07',
              periodOfCover: 0,
              waitDays: 30,
              excessAmount: 0,
              paymentOptions: [
                {
                  preferredPayment: true,
                  paymentType: 'D',
                  paymentProfile: 'P',
                  firstPayment: 12,
                  subsequentPayment: 12,
                  numPayments: 11,
                  numSubsequentPayments: 10,
                  paymentFrequency: 1,
                  fee: 144,
                },
                {
                  preferredPayment: true,
                  paymentType: 'C',
                  paymentProfile: 'P',
                  firstPayment: 0,
                  subsequentPayment: 0,
                  numPayments: 1,
                  paymentFrequency: 1,
                  fee: 144,
                },
              ],
            },
            {
              quoteId: '9',
              contractTypeCode: ContractType.Insurance,
              productType: 'I',
              companyCode: 'D',
              schemeCode: 'VU6',
              paymentTerm: 'IPM',
              coverType: 'P-STD',
              isBundle: false,
              breakdownStartDate: '2021-01-07',
              periodOfCover: 0,
              waitDays: 30,
              excessAmount: 50,
              paymentOptions: [
                {
                  preferredPayment: true,
                  paymentType: 'D',
                  paymentProfile: 'P',
                  firstPayment: 12,
                  subsequentPayment: 12,
                  numPayments: 11,
                  numSubsequentPayments: 10,
                  paymentFrequency: 1,
                  fee: 140,
                },
                {
                  preferredPayment: true,
                  paymentType: 'C',
                  paymentProfile: 'P',
                  firstPayment: 0,
                  subsequentPayment: 0,
                  numPayments: 1,
                  paymentFrequency: 1,
                  fee: 140,
                },
              ],
              metadata: {
                companyCode: 'D',
                schemeCode: 'VU6',
                inWarranty: true,
                outOfWarranty: false,
              },
            },
          ],
          applianceDetails: {
            purchaseMonth: 3,
            purchaseYear: 2018,
            purchasePrice: 200,
            applianceCode: 'AW',
            applianceName: 'Washing Machine',
            applianceCategory: 'Washing Machine',
            applianceIcon:
              'https://sit-cms.domesticandgeneral.com/dam/jcr:1804b857-53c9-4315-99f9-e0161b00c737/placeholder-why-get-protected.png',
            applianceImage:
              'https://sit-cms.domesticandgeneral.com/dam/jcr:1804b857-53c9-4315-99f9-e0161b00c737/placeholder-why-get-protected.png',
            brandCode: 'HOTP',
            brandName: 'Hotpoint',
            warranty: 12,
            inWarranty: true,
          },
        },
      },
      {
        requestSource: 'DandGUK',
        basketId: '1',
        itemId: '2',
        itemType: 'QUOTE',
        created: '2020-12-08T11:39:12.557Z',
        updated: '2020-12-08T11:39:12.557Z',
        expiry: '2020-12-08T14:27:13.000Z',
        status: 'ACTIVE',
        data: {
          quotes: [
            {
              quoteId: '10',
              contractTypeCode: ContractType.Insurance,
              productType: 'I',
              companyCode: 'D',
              schemeCode: 'VU6',
              paymentTerm: 'IPM',
              coverType: 'G-STD',
              isBundle: false,
              breakdownStartDate: '2021-01-07',
              periodOfCover: 0,
              waitDays: 30,
              excessAmount: 0,
              paymentOptions: [
                {
                  preferredPayment: true,
                  paymentType: 'C',
                  paymentProfile: 'P',
                  firstPayment: 0,
                  subsequentPayment: 0,
                  numPayments: 1,
                  paymentFrequency: 1,
                  fee: 96,
                },
              ],
            },
            {
              quoteId: '11',
              contractTypeCode: ContractType.Insurance,
              productType: 'I',
              companyCode: 'D',
              schemeCode: 'VU6',
              paymentTerm: 'IPM',
              coverType: 'P-STD',
              isBundle: false,
              breakdownStartDate: '2021-01-07',
              periodOfCover: 0,
              waitDays: 30,
              excessAmount: 0,
              paymentOptions: [
                {
                  preferredPayment: true,
                  paymentType: 'D',
                  paymentProfile: 'P',
                  firstPayment: 12,
                  subsequentPayment: 12,
                  numPayments: 11,
                  numSubsequentPayments: 10,
                  paymentFrequency: 1,
                  fee: 144,
                },
                {
                  preferredPayment: true,
                  paymentType: 'C',
                  paymentProfile: 'P',
                  firstPayment: 0,
                  subsequentPayment: 0,
                  numPayments: 1,
                  paymentFrequency: 1,
                  fee: 144,
                },
              ],
            },
          ],
          applianceDetails: {
            purchaseMonth: 3,
            purchaseYear: 2018,
            purchasePrice: 200,
            applianceCode: 'E7',
            applianceName: 'Condensing Boiler',
            applianceCategory: 'Condensing Boiler',
            brandCode: 'WR',
            brandName: 'Worcester',
            warranty: 12,
            inWarranty: true,
          },
        },
      },
    ],
  },
} as CreateQuoteResponse;

export const GA_TAGS_MOCK: GoogleTagConfig = {
  config: {
    showLegacyTags: true,
  },
  checkoutLoggedInUser: {
    event: 'salesGA',
    pagename: 'payment - speedy',
    genericpagename: 'checkout',
    category: 'sales-quote',
    eventSales: 'eecCheckout',
  },
  applianceDetailsLandingTags: {
    genericpagename: 'goal-search',
    category: 'sales-quote',
    pagename: 'get-protected-appliance-details',
    event: 'salesGA',
    eventSales: 'ApplianceDetailsLanding',
  },
  paymentDetailsSummaryCommonData: {
    event: 'salesGA',
  },
  quoteLandingTags: {
    event: 'salesGA',
    eventSales: 'eecCheckoutButton',
    pagename: 'quote-gen',
    genericpagename: 'quote-gen',
  },
  checkoutLandingPage: {
    event: 'salesGA',
    eventSales: 'eecCheckoutOption',
    pagename: 'checkout-option',
    genericpagename: 'checkout-option',
    category: 'sales-quote',
  },
  checkoutPersonalDetails: {
    event: 'salesGA',
    pagename: 'policy-details-guest',
    genericpagename: 'checkout',
    category: 'sales-quote',
    checkoutOption: 'normal',
    eventSales: 'eecCheckout',
  },
  applianceFormData: {
    event: 'salesGA',
    eventCategory: 'Form - GoalSearch',
    eventSales: 'ApplianceDetails',
    pagename: 'get-protected-appliance-details',
  },
  brandFormData: {
    event: 'salesGA',
    eventCategory: 'Form - GoalSearch',
    eventSales: 'ApplianceDetails',
    pagename: 'get-protected-appliance-details',
  },
  workingOrderFormData: {
    event: 'salesGA',
    eventCategory: 'Form - GoalSearch',
    eventSales: 'GoodWorkingOrder',
  },
  purchaseDateFormData: {
    event: 'salesGA',
    eventCategory: 'Form - GoalSearch',
    eventSales: 'PurchaseDate',
  },
  underGuaranteeFormData: {
    event: 'salesGA',
    eventCategory: 'Form - GoalSearch',
    eventSales: 'ApplianceGuarantee',
  },
  purchasePriceFormData: {
    event: 'salesGA',
    eventCategory: 'Form - GoalSearch',
    eventSales: 'PurchasePrice',
  },
  quotePriceData: {
    event: 'salesGA',
    eventCategory: 'sales-quote-price-impression',
    eventSales: 'quote-impression',
  },
  onApplianceFormSubmission: {
    event: 'salesGA',
    eventCategory: 'Form - GoalSearch',
    eventSales: 'GetQuote',
  },
  addItemToBasket: {
    event: 'salesGA',
    eventSales: 'eec.add',
  },
  addAnotherAppliance: {
    event: 'salesGA',
    pagename: 'add-another-appliance',
    category: 'sales-quote',
    genericpagename: 'goal-search',
    eventSales: 'ApplianceDetailsAdd',
  },
  proceedToCheckoutMultiBasket: {
    event: 'salesGA',
    eventCategory: 'Form - GoalSearch',
    eventSales: 'GetQuote',
    eventLabel: 'get-quote submitted',
  },
  deleteItemFromBasket: {
    event: 'salesGA',
    eventSales: 'remove-cart-x',
    eventCategory: 'remove-from-cart-interaction',
    eventAction: 'x click',
  },
  confirmItemDeletionFromBasket: {
    event: 'salesGA',
    eventSales: 'eec.remove',
  },
  cancelItemDeletionFromBasket: {
    event: 'salesGA',
    eventCategory: 'remove-from-cart-interaction',
    eventAction: 'cancel cta',
  },
  invalidApplianceFormSubmission: {
    event: 'salesGA',
    eventSales: 'errorForm',
    pagename: 'get-protected-appliance-details-error',
    eventCategory: 'Form - GoalSearch',
    eventAction: 'error',
  },
  boilerMultiItemReplace: {
    event: 'salesGA',
    eventSales: 'multi-item-boiler',
    pagename: 'get-protected-appliance-details',
    eventCategory: 'Form - GoalSearch',
  },
  personalDetailsFieldCompletionData: {
    event: 'salesGA',
    eventSales: 'eecCheckoutForm',
    eventCategory: 'Form - PolicyDetails',
    eventAction: 'completed',
  },
  personalDetailsCityCompletionData: {
    event: 'salesGA',
    eventSales: 'eecCheckoutForm',
    eventCategory: 'Form - PolicyDetails',
  },
  marketingPreferencesData: {
    event: 'salesGA',
    eventSales: 'eecCheckoutForm',
    eventCategory: 'Form - PolicyDetails',
    eventAction: 'marketing-preferences',
  },
  planDocumentPreferencesData: {
    event: 'salesGA',
    eventSales: 'eecCheckoutForm',
    eventCategory: 'Form - PolicyDetails',
    eventAction: 'document plan',
    eventLabel: 'post',
  },
  checkoutSelectPaymentCommonData: {
    event: 'salesGA',
    eventSales: 'payment-options',
    pagename: 'payment-options',
    genericpagename: 'checkout',
    category: 'sales-quote',
  },
  checkoutDirectDebitCommonData: {
    event: 'salesGA',
    eventSales: 'eecCheckout',
    pagename: 'payment',
    genericpagename: 'checkout',
    category: 'sales-quote',
  },
  checkoutCardCommonData: {
    event: 'salesGA',
    eventSales: 'eecCheckout',
    genericpagename: 'checkout',
    category: 'sales-quote',
  },
  paymentDetailsFormFieldCompletion: {
    event: 'salesGA',
    eventSales: 'eecCheckoutForm',
    eventCategory: 'Form - payment',
    eventAction: 'completed',
  },
  paymentDetailsFormBuyNowData: {
    event: 'salesGA',
    eventSales: 'eecCheckoutFormCTA',
    eventCategory: 'Form - payment',
    eventAction: 'buy now',
  },
  orderConfirmationTaggingData: {
    event: 'salesGA',
    eventSales: 'eecPurchase',
    pagename: 'order-confirmation',
    genericpagename: 'order-confirmation',
    category: 'sales-quote',
  },
  inclusionExclusionsData: {
    eventCategory: 'quote-summary',
    eventAction: 'quote tab clicked',
  },
  quoteSummaryPage: {
    landing: {
      event: 'salesGA',
      eventSales: 'quote-gen-page',
      pagename: 'quote-gen',
      genericpagename: 'quote-gen',
    },
    primaryCTA: {
      event: 'salesGA',
      eventSales: 'eecCheckoutButton',
    },
    secondaryCTA: {
      event: 'salesGA',
      eventSales: 'add-another-cta',
      eventCategory: 'quote-summary',
      eventAction: 'add another appliance',
      eventLabel: 'CTA',
    },
  },
};

export const MOCK_MULTI_ITEM_QUOTE_SUMMARY: Quote[] = [
  {
    applianceCode: 'CN',
    applianceCategory: 'Fridges & Freezers',
    applianceName: 'American Fridge Freezer',
    brandCode: 'BEKO',
    brandName: 'Beko',
    purchaseDate: new Date('2017-10-01T03:00:00.000Z'),
    applianceIcon: 'assets/images/catalogue-icons/american-fridge-freezer.svg',
    cover: {
      name: CoverTypeName.STD,
      quoteId: '7bf963e2-6547-4a8a-8284-fd875478cd0c',
      isBundle: false,
      coverType: CoverType.Gold,
      excessAmount: 0,
      contractTypeCode: ContractType.Insurance,
      productType: 'AC',
      companyCode: 'D',
      schemeCode: 'VU6',
      breakdownStartDate: '2021-11-03',
      periodOfCover: 0,
      waitDays: 30,
      paymentTerm: 'IPM',
      paymentOptions: {
        directDebit: {
          paymentType: PaymentType.DirectDebit,
          preferredPayment: true,
          paymentProfile: 'P',
          numPayments: 0,
          paymentFrequency: 1,
          firstPayment: 9.4,
          subsequentPayment: 9.4,
          fee: 9.4,
          numSubsequentPayments: 0,
        },
      },
    },
    itemId: '82ba47f3-e7e1-42b7-bd0c-0c0dedf97633',
    itemType: ItemType.Quote,
  },
];
