import { Component, EventEmitter, Input, Output } from '@angular/core';

import { NavigationLink } from '@domgen/dgx-fe-business-models';

@Component({
  selector: 'dgx-ui-wl-header-nav-link',
  templateUrl: './header-nav-link.component.html',
  styleUrls: ['./header-nav-link.component.scss'],
})
export class HeaderNavLinkComponent {
  @Input() data: NavigationLink | undefined;

  /**
   * Emits a click event back to the parent component
   */
  @Output() clicked: EventEmitter<MouseEvent> = new EventEmitter();

  /**
   * Listens and fires emit `clicked` when clicked
   */
  onClick(e: MouseEvent) {
    this.clicked.emit(e);
  }
}
