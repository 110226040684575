import {
  AbstractControl,
  AsyncValidatorFn,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { Address } from './address.interface';

/**
 * A union of all expected form element config objects except GenericFieldDef
 */
export type FieldDef =
  | TextInputDef
  | TextAreaDef
  | TextPasswordDef
  | CheckboxToggleDef
  | TypeAheadDef
  | RadioDef
  | YesNoDef
  | SelectDef
  | MonthYearDateDef
  | YearDateDef
  | SubmitDef
  | SortCodeDef
  | HtmlContentDef
  | AddressDef
  | AcceptDef
  | ListBoxDef
  | AddressListDef
  | CustomFieldDef; // stricter type checking outside of templates

export interface TextInputDef {
  controlType: ControlType.INPUT;
  controlName: string;
  validators: ValidatorFn[];
  validationMessages?: ValidationMessage[];
  initialValue?: string;
  label?: Label;
  placeholder?: string;
  disabled?: boolean;
  classes?: string;
  maxlength?: number;
  hint?: string;
  tooltip?: string;
  type?: 'text' | 'number';
  onBlur?: (value: string, control: AbstractControl | null) => void;
}
export interface TextAreaDef {
  controlType: ControlType.TEXTAREA;
  controlName: string;
  validators: ValidatorFn[];
  validationMessages?: ValidationMessage[];
  initialValue?: string;
  label?: Label;
  placeholder?: string;
  disabled?: boolean;
  classes?: string;
  maxlength?: number;
  hint?: string;
  tooltip?: string;
  onBlur?: (value: string, control: AbstractControl | null) => void;
}

export interface TextPasswordDef
  extends Omit<TextInputDef, 'type' | 'controlType'> {
  type?: 'password';
  controlType: ControlType.PASSWORD;
  hideStrengthChecker?: boolean;
}

export interface MonthYearUpdater {
  minimumAgeInMonths?: number;
  defaultMonth?: number;
}

export interface SelectDef {
  controlType: ControlType.SELECT;
  controlName: string;
  validators: Validators[];
  validationMessages?: ValidationMessage[];
  initialValue?: string;
  label?: Label;
  placeholder?: string;
  disabled?: boolean;
  disabledStream$?: Observable<boolean>;
  optionsStream$?: Observable<string[] | OptionItem[]>;
  options?: (string | OptionItem)[];
  classes?: string;
  hint?: string;
  tooltip?: string;
  showPreloader?: boolean;
  loadingStream$?: Observable<boolean>;
  fitContent?: boolean;
}

export interface CheckboxToggleDef {
  controlType: ControlType.CHECKBOX | ControlType.TOGGLE | ControlType.ACCEPT;
  controlName: string;
  validators: Validators[];
  validationMessages?: ValidationMessage[];
  initialValue?: boolean;
  label?: Label;
  disabled?: boolean;
  classes?: string;
}

export interface RadioDefBase {
  controlType: ControlType;
  controlName: string;
  validators: Validators[];
  validationMessages?: ValidationMessage[];
  options: OptionItem<string | boolean | number>[];
  initialValue?: string | boolean | number;
  label?: Label;
  classes?: string;
  hint?: string;
  message?: string;
  tooltip?: string;
  inline?: boolean;
}

export interface RadioDef extends RadioDefBase {
  controlType: ControlType.RADIO;
}

export interface YesNoDef extends RadioDefBase {
  controlType: ControlType.YESNO;
  initialValue?: string | boolean;
  options: OptionItem<string | boolean>[];
}

export interface AcceptDef extends CheckboxToggleDef {
  controlType: ControlType.TOGGLE;
}

export interface MonthYearDateDef {
  controlType: ControlType.MONTHYEARDATE;
  controlName: string;
  validators: Validators[];
  validationMessages?: ValidationMessage[];
  initialValue?: { month: number; year: number };
  label?: Label;
  monthLabel: Label;
  yearLabel: Label;
  hint: string;
  monthOptions: number[];
  yearOptions: number[];
  classes?: string;
  tooltip?: string;
}

export interface YearDateDef {
  controlType: ControlType.YEARDATE;
  controlName: string;
  validators: Validators[];
  validationMessages?: ValidationMessage[];
  initialValue?: { month: number; year: number };
  label?: Label;
  monthLabel?: Label;
  yearLabel: Label;
  hint: string;
  classes?: string;
  tooltip?: string;
  values: {
    year: number;
    month: number;
    label: string;
  }[];
}

export interface TypeAheadDef {
  controlType: ControlType.TYPEAHEAD;
  controlName: string;
  validators: Validators[];
  asyncValidators?: AsyncValidatorFn[];
  validationMessages?: ValidationMessage[];
  initialValue?: string;
  label?: Label;
  placeholder?: string;
  optionsStream$: Observable<string[] | OptionItem[]>;
  classes?: string;
  maxlength?: number;
  hint?: string;
  startSearchFromCharacter?: number;
  tooltip?: string;
  debounceTime?: number;
  optionsLimit?: number;
  showAllOptions?: boolean;
  disableOnSelect?: boolean;
}

export interface ListBoxDef {
  controlType: ControlType.LISTBOX;
  controlName: string;
  validators: Validators[];
  asyncValidators?: AsyncValidatorFn[];
  validationMessages?: ValidationMessage[];
  initialValue?: string;
  label?: Label;
  placeholder?: string;
  classes?: string;
  maxlength?: number;
  hint?: string;
  startSearchFromCharacter?: number;
  tooltip?: string;
  debounceTime?: number;
  optionsLimit?: number;
  showAllOptions?: boolean;
  disableOnSelect?: boolean;
  optionsStream$: Observable<string[]>;
  hightlightedText?: string;
}

export interface AddressDef {
  controlType: ControlType.ADDRESS;
  controlName: string;
  validators: Validators[];
  validationMessages?: ValidationMessage[];
  initialValue?: Address | null;
  label?: Label;
  placeholder?: string;
  addressLine1Label?: Label;
  addressLine1Placeholder?: string;
  addressLine2Label?: Label;
  addressLine2Placeholder?: string;
  cityLabel?: Label;
  cityPlaceholder?: string;
  countyLabel?: Label;
  countyPlaceholder?: string;
  postcodeLabel?: Label;
  postcodePlaceholder?: string;
  startSearchFromCharacter?: number;
  debounceTime?: number;
}

export interface AddressListDef
  extends Omit<AddressDef, 'controlType' | 'initialValue'> {
  controlType: ControlType.ADDRESSLIST;
  initialValue: Address;
  optionsStream$?: Observable<string[]>;
  hightlightedText?: string;
}

export interface SubmitDef {
  controlType: ControlType.SUBMIT;
  label: Label;
  classes?: string;
  excludeFromFormGroup: true;
  disabled?: boolean; // true = button remains disabled until form is valid
}

export interface CustomFieldDef {
  controlType: string;
  controlName: string;
  customConfig: {
    [key: string]: unknown; // generic defs can have any other keys for custom controls
  };
  initialValue?: unknown;
  excludeFromFormGroup?: boolean;
}

/**
 * a super set of all form element config objects' properties except CustomFieldDef
 */
export interface GenericFieldDef {
  controlType: string;
  controlName?: string;
  validators?: Validators[];
  asyncValidators?: AsyncValidatorFn[];
  validationMessages?: ValidationMessage[];
  initialValue?: unknown;
  label?: Label;
  placeholder?: string;
  disabled?: boolean;
  options?: (string | OptionItem<unknown>)[];
  optionsStream$?: Observable<string[] | OptionItem<unknown>[]>;
  monthOptions?: number[];
  yearOptions?: number[];
  classes?: string;
  maxlength?: number;
  hint?: string;
  tooltip?: string;
  htmlContent?: string;
  excludeFromFormGroup?: boolean;
  startSearchFromCharacter?: number;
}

export interface Label {
  text: string;
  classes?: string;
}

export interface ValidationMessage {
  message: string;
  type: string;
}

export enum ControlType {
  INPUT = 'input',
  TEXTAREA = 'textarea',
  PASSWORD = 'password',
  TOGGLE = 'toggle',
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  YESNO = 'yesno',
  MONTHYEARDATE = 'monthyeardate',
  YEARDATE = 'yeardate',
  TYPEAHEAD = 'typeahead',
  LISTBOX = 'listbox',
  SELECT = 'select',
  ADDRESS = 'address',
  ADDRESSLIST = 'addresslist',
  SUBMIT = 'submit',
  SORTCODE = 'sortCode',
  HTMLCONTENT = 'htmlcontent',
  ACCEPT = 'accept',
}

export interface InvalidFormSubmissionPayload {
  value: unknown;
  form: FormGroup;
  fieldset: FieldDef[];
}

export enum DirectDebitControls {
  MonthlyPayment = 'monthlyPayment',
  AccountHolderName = 'accountHolderName',
  AccountNumber = 'accountNumber',
  SortCode = 'sortCode',
  Sort1 = 'sort1',
  Sort2 = 'sort2',
  Sort3 = 'sort3',
  PreferredPaymentDay = 'preferredPaymentDay',
}

export interface SortCodeDef {
  controlType: ControlType.SORTCODE;
  controlName: string;
  validators: Validators[];
  validationMessages?: ValidationMessage[];
  initialValue?: string;
  label?: Label;
  placeholder?: string;
  disabled?: boolean;
  classes?: string;
  maxlength?: number;
  hint?: string;
  tooltip?: string;
  type?: 'text' | 'number';
}

export interface SortCodeInputs {
  [key: string]: { touched: boolean; value: string };
}

export interface HtmlContentDef {
  controlType: ControlType.HTMLCONTENT;
  htmlContent?: string;
  disabled?: boolean;
}

export interface OptionItem<T = string> {
  label: string;
  value: T;
}
