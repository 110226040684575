import { Injectable } from '@angular/core';
import {
  CmsFormField,
  OfferRenewalFormField,
} from '@domgen/dgx-fe-business-models';
import {
  ControlType,
  FieldDef,
  SubmitDef,
  TextInputDef,
} from '@domgen/dgx-fe-dynamic-form-builder';
import * as CmsHelpers from '@domgen/dgx-fe-business-components';
import { AbstractControl, Validators } from '@angular/forms';
import { OfferRenewalFormSubmit } from '@common/util-models';
import {
  OFFER_REGEX,
  POSTCODE_REGEX,
  RENEWAL_REGEX,
} from '@common/util-foundation';

@Injectable()
export class OfferRenewalFormConfigService {
  getFormbuilderConfig(
    cmsFormData: CmsFormField[],
    referenceType: string | undefined,
    formData: OfferRenewalFormSubmit | null
  ): FieldDef[] {
    if (referenceType === 'offer') {
      return [
        this.getInputDefByFieldName(
          cmsFormData,
          OfferRenewalFormField.ReferenceNumber,
          formData?.referenceNumber
        ),
        this.getInputDefByFieldName(
          cmsFormData,
          OfferRenewalFormField.PostCode,
          formData?.postCode
        ),
        this.submitButtonDef(cmsFormData, OfferRenewalFormField.FindMyQuote),
      ] as FieldDef[];
    } else if (referenceType === 'renewal') {
      return [
        this.getInputDefByFieldName(
          cmsFormData,
          OfferRenewalFormField.PlanNumber,
          formData?.planNumber
        ),
        this.getInputDefByFieldName(
          cmsFormData,
          OfferRenewalFormField.PostCode,
          formData?.postCode
        ),
        this.submitButtonDef(cmsFormData, OfferRenewalFormField.RenewMyPlan),
      ] as FieldDef[];
    }
    return [];
  }

  private getInputDefByFieldName(
    cmsFormData: CmsFormField[],
    formField: OfferRenewalFormField,
    defaultValue: string | undefined
  ): FieldDef {
    let fieldDef: FieldDef;
    const cmsFormField = CmsHelpers.getFormField<CmsFormField>(
      cmsFormData,
      formField
    );
    const defaultFieldDef = CmsHelpers.getDefaultFieldDef(
      cmsFormField,
      formField
    );

    switch (formField) {
      case OfferRenewalFormField.ReferenceNumber:
        fieldDef = {
          ...defaultFieldDef,
          controlType: ControlType.INPUT,
          maxlength: 15,
          validators: [Validators.required, Validators.pattern(OFFER_REGEX)],
          initialValue: defaultValue,
          onBlur: (val, control) => {
            this.formatReferenceNumber(val, control);
          },
        } as TextInputDef;
        break;
      case OfferRenewalFormField.PlanNumber:
        fieldDef = {
          ...defaultFieldDef,
          controlType: ControlType.INPUT,
          maxlength: 10,
          validators: [Validators.required, Validators.pattern(RENEWAL_REGEX)],
          initialValue: defaultValue,
        } as TextInputDef;
        break;
      case OfferRenewalFormField.PostCode:
        fieldDef = {
          ...defaultFieldDef,
          controlType: ControlType.INPUT,
          maxlength: 8,
          validators: [Validators.required, Validators.pattern(POSTCODE_REGEX)],
          initialValue: defaultValue,
        } as TextInputDef;
        break;
      default:
        fieldDef = {
          ...defaultFieldDef,
        } as FieldDef;
        break;
    }

    return fieldDef;
  }

  private submitButtonDef(
    applianceCmsData: CmsFormField[],
    formField: OfferRenewalFormField
  ): SubmitDef {
    const submitFormField = CmsHelpers.getFormField(
      applianceCmsData,
      formField
    );

    return {
      controlType: ControlType.SUBMIT,
      label: { text: submitFormField.label },
      classes: 'btn btn--primary',
      excludeFromFormGroup: true,
      disabled: true,
    };
  }

  private formatReferenceNumber(
    val: string | undefined,
    control: AbstractControl | null
  ) {
    if (val?.length) {
      val = val.match(/\d{3}(?=\d{2,3})|\d+/g)?.join('-');
      if (val?.length && control?.status === 'VALID') {
        control.setValue(val);
        control.updateValueAndValidity();
      }
    }
  }
}
