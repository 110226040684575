import { HttpErrorResponse } from '@angular/common/http';
import {
  CreateQuoteRequest,
  ApplianceFormSubmit,
  CreateQuoteResponse,
} from '@common/util-models';
import { createAction, props } from '@ngrx/store';

export const createQuoteRequest = createAction(
  '[Appliance Details] Create Appliance Quote: Request',
  props<{
    quoteRequest: CreateQuoteRequest;
    formData: ApplianceFormSubmit;
  }>()
);

export const createQuote = createAction(
  '[Appliance Details] Create Appliance Quote',
  props<{
    quoteRequest: CreateQuoteRequest;
    formData: ApplianceFormSubmit;
  }>()
);

export const createQuoteSuccess = createAction(
  '[Appliance Details] Create Appliance Quote: Success',
  props<{
    quoteResponse: CreateQuoteResponse;
    formData: ApplianceFormSubmit;
  }>()
);

export const createQuoteFailure = createAction(
  '[Appliance Details] Create Appliance Quote: Failure',
  props<{
    error: HttpErrorResponse;
  }>()
);
