<sales-loader [@fadeInOut] *ngIf="loading$ | async"></sales-loader>
<dgx-ui-wl-header clientLogoPath="assets/images/ao-logo.svg" [navigationLinks]="headerNavigationLinks"></dgx-ui-wl-header>
<oren-banner *ngIf="currentRoute === routeCheckoutOrderConfirmation" [isInsurance$]="isInsurance$"></oren-banner>
<main>
  <ui-progress-tracker-route-based [steps]="progressSteps$ | async" [currentRoute]="currentRoute">
  </ui-progress-tracker-route-based>
  <router-outlet></router-outlet>
</main>
<dgx-ui-wl-ao-footer
  [protectLinks]="footerProtectLinks"
  [legalLinks]="footerLegalLinks"
  [usefulLinks]="footerUsefulLinks"
></dgx-ui-wl-ao-footer>
