import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, merge, Observable, timer } from 'rxjs';
import { first, mapTo, switchMap, throttleTime } from 'rxjs/operators';
import { filterNullUndefined } from '../../operators/filter-null-undefined';
import { WINDOW } from '../../tokens/window.token';
import { BuildConfigService } from '../build.config.service';

@Injectable({
  providedIn: 'root',
})
export class SessionInactivityService {
  private readonly sessionInactive$: Observable<void> = merge(
    this.router.events,
    fromEvent(this.window, 'mousemove'),
    fromEvent(this.window, 'click')
  ).pipe(
    throttleTime(10000),
    mapTo(this.buildConfigService.config.sessionInactivityDurationInMinutes),
    filterNullUndefined(),
    switchMap((durationInMinutes: number) => timer(durationInMinutes * 60000)),
    mapTo(undefined),
    first()
  );

  constructor(
    @Inject(WINDOW) private readonly window: Window,
    private readonly buildConfigService: BuildConfigService,
    private readonly router: Router
  ) {}

  monitor(): Observable<void> {
    return this.sessionInactive$.pipe(first());
  }
}
