/* istanbul ignore file */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderModule } from './header';
import { FooterModule } from './footer';
import { SharedAoComponentsModule } from './ao';

@NgModule({
  imports: [CommonModule, HeaderModule, FooterModule, SharedAoComponentsModule],
})
export class SharedUiWhitelabelComponentsModule {}
