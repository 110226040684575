import { AbstractControl } from '@angular/forms';
import { MonoTypeOperatorFunction, pipe } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import {
  disableControls,
  enableControls,
  getAllControls,
  getAllDisabledControls,
} from '../utils/form.utils';

export function disableControl(
  control: AbstractControl | null | undefined
): MonoTypeOperatorFunction<boolean> {
  let existingDisabledControls: AbstractControl[] = [];
  const controls = getAllControls(control);

  return pipe(
    filter((disabled: boolean) => disabled !== control?.disabled),
    tap((disabled: boolean) => {
      if (disabled) {
        existingDisabledControls = getAllDisabledControls(control);
        disableControls(...controls);
      } else {
        enableControls(...controls);
        disableControls(...existingDisabledControls);
      }
    })
  );
}
