<ui-panel variant="highlight-advertising">
  <ui-panel-header>
    <ui-panel-title>Why get protected?</ui-panel-title>
  </ui-panel-header>
  <ui-panel-body>
    <ul>
      <li><img
        src="assets/images/icon-repair.svg">
        <div class="content"><h5 class="typog-body-large-bold">Keep your home running smoothly</h5>
          <p>From heating to appliances, we protect the things you and your family use every day.</p></div>
      </li>
      <li><img
        src="assets/images/icon-savings.svg">
        <div class="content"><h5 class="typog-body-large-bold">Enjoy access to our expert engineers</h5>
          <p>We have a nationwide network of engineers to help you, should you need it.</p></div>
      </li>
      <li><img
        src="assets/images/icon-contact-us.svg">
        <div class="content"><h5 class="typog-body-large-bold">Manage things easily online with My Account</h5>
          <p>Find help and advice, book repairs and manage your plans all in the one place.</p></div>
      </li>
    </ul>
  </ui-panel-body>
</ui-panel>
