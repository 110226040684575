import { Action, createReducer, on } from '@ngrx/store';

import {
  ApplianceDetailsConfig,
  CheckoutConfig,
  QuotesConfig,
  SidedoorConfig,
  RemoteData,
  remoteDataError,
  remoteDataLoading,
  remoteDataNotFetched,
  remoteDataOK,
  DiscountStoreConfig,
} from '@common/util-models';
import * as FeatureConfigActions from './feature-config.actions';
export const FEATURE_CONFIG_FEATURE_KEY = 'feature-config';

export interface FeatureConfigState {
  remoteState: RemoteData<null>;
  applianceDetails: ApplianceDetailsConfig | undefined;
  quotes: QuotesConfig | undefined;
  checkout: CheckoutConfig | undefined;
  sidedoor: SidedoorConfig | undefined;
  discountStore: DiscountStoreConfig | undefined;
}

export interface FeatureConfigPartialState {
  readonly [FEATURE_CONFIG_FEATURE_KEY]: FeatureConfigState;
}

export const initialState: FeatureConfigState = {
  remoteState: remoteDataNotFetched(),
  quotes: undefined,
  applianceDetails: undefined,
  checkout: undefined,
  sidedoor: undefined,
  discountStore: undefined,
};

const featureConfigReducer = createReducer(
  initialState,

  on(FeatureConfigActions.loadFeatureConfig, (state) => ({
    ...state,
    remoteState: remoteDataLoading(),
  })),

  on(FeatureConfigActions.loadFeatureConfigSuccess, (state, { response }) => ({
    ...state,
    ...response,
    remoteState: remoteDataOK(null),
  })),

  on(FeatureConfigActions.loadFeatureConfigFailure, (state, { error }) => ({
    ...state,
    remoteState: remoteDataError(error),
  }))
);

export function reducer(state: FeatureConfigState | undefined, action: Action) {
  return featureConfigReducer(state, action);
}
