import { Inject, Injectable } from '@angular/core';
import { ConfigAdapter, Environment } from '@common/util-models';
import { ENVIRONMENT, WINDOW } from '../tokens';

@Injectable()
export class RequestSourceConfigAdapterService implements ConfigAdapter {
  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    @Inject(WINDOW) private window: Window
  ) {}

  getConfig(): { [key: string]: unknown } {
    /*
      We are using window.location over router here, because we are affecting
      the config which will change headers for API calls that are triggered
      via route resolvers.

      Using the router means we have to subscribe to the queryParams which
      prevents us from changing the config before API calls have started.
     */
    const url = new URL(this.window.location.href);
    const requestSource = this.findRequestSource(url);
    const suffix = this.getSuffix(url);
    return {
      catalogueStore: `${requestSource}${suffix}`,
    };
  }

  private getSuffix(url: URL) {
    const storeSuffix = url.searchParams.get('store');
    if (storeSuffix && storeSuffix.match(/^[A-Za-z0-9]+$/)) {
      return `_${storeSuffix}`;
    }
    return '';
  }

  private findRequestSource(url: URL) {
    const { hostname } = url;
    if (!this.environment.domainBasedRequestSources) {
      return this.environment.catalogueStore;
    }
    const match = this.environment.domainBasedRequestSources.find((def) =>
      def.pattern.test(hostname)
    );
    return (match && match.requestSource) || this.environment.catalogueStore;
  }
}
