import { createReducer, Action, on } from '@ngrx/store';
import { CheckoutPersonalDetailsFormValue } from '@oren/util-foundation';
import * as CheckoutActions from './checkout.actions';

export const OREN_CHECKOUT_FEATURE_KEY = 'offerRenewal.checkout';

export interface CheckoutState {
  email: string;
  paperlessDocumentPreference: boolean;
  termsAndConditionsAccepted: boolean;
}

export const initialState: CheckoutState = {
  email: '',
  paperlessDocumentPreference: false,
  termsAndConditionsAccepted: false,
};

const checkoutReducer = createReducer(
  initialState,
  on(
    CheckoutActions.checkoutPersonalDetailsFormSubmit,
    (
      state: CheckoutState,
      payload: { formValue: CheckoutPersonalDetailsFormValue }
    ): CheckoutState => {
      return {
        ...state,
        ...payload.formValue,
      };
    }
  )
);

export function reducer(state: CheckoutState | undefined, action: Action) {
  return checkoutReducer(state, action);
}
