/* istanbul ignore file */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer.component';

const declarations = [
  FooterComponent,
];

@NgModule({
  declarations: declarations,
  exports: declarations,
  imports: [CommonModule, RouterModule],
})
export class FooterModule {}
