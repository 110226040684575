<dgx-dfb-field-layout
  [id]="id"
  [legend]="field?.label?.text"
  [tooltip]="field?.tooltip"
  [hint]="field?.hint"
  [errorMessage]="errorMessage"
>
  <div
    class="radio"
    [ngClass]="field?.classes || []"
    [class.radio--inline]="field.inline"
  >
    <label *ngFor="let option of field?.options">
      <input
        (click)="writeValue(option.value, true)"
        [checked]="value === option?.value"
        [name]="field?.controlName"
        [value]="option?.value"
        [disabled]="disabled"
        class="form-control"
        type="radio"
      />
      <span>{{ option.label }}</span>
    </label>
  </div>
</dgx-dfb-field-layout>
