import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AnalyticsData, CmsFormField } from '@domgen/dgx-fe-business-models';
import { OfferRenewalFormStateService } from './offer-renewal-form-state.service';
import { OfferRenewalFormConfigService } from './offer-renewal-form-config.service';
import { OfferRenewalFormSubmit } from '@common/util-models';

@Component({
  selector: 'oren-offer-renewal-form',
  templateUrl: './offer-renewal-form.component.html',
  styleUrls: ['./offer-renewal-form.component.scss'],
  providers: [OfferRenewalFormStateService, OfferRenewalFormConfigService],
})
export class OfferRenewalFormComponent {
  @Output() analytics: EventEmitter<AnalyticsData> = new EventEmitter();
  @Output() validatedSubmit = this.offerRenewalFormStateService
    .validatedSubmit$;

  @Input() set cmsFormData(value: CmsFormField[] | null) {
    if (value) {
      this.offerRenewalFormStateService.setCmsFormData(value);
    }
  }

  @Input() set referenceType(value: string | null | undefined) {
    if (value) {
      this.offerRenewalFormStateService.setReferenceType(value);
    }
  }

  @Input() set formData(value: OfferRenewalFormSubmit | null | undefined) {
    if (value !== undefined) {
      this.offerRenewalFormStateService.setPrefilledFormData(value);
    }
  }

  @Input() set validate(validate: boolean) {
    this.offerRenewalFormStateService.validate(validate);
  }

  readonly vm$ = this.offerRenewalFormStateService.vm$;

  /**
   * The underlying form group
   */
  @Output() form = this.offerRenewalFormStateService.formGroup$;

  constructor(
    private offerRenewalFormStateService: OfferRenewalFormStateService
  ) {}

  handleAnalytics(event: AnalyticsData) {
    this.analytics.emit(event);
  }

  handleValidFormSubmission(value: OfferRenewalFormSubmit) {
    this.offerRenewalFormStateService.updateSubmittedForm(value);
  }

  handleValueChanges(event: unknown) {
    this.offerRenewalFormStateService.updateFormValue(event);
  }
}
