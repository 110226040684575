<ui-banner>
  <div>
    <span *ngIf="!isCard"
      >Don’t miss out<span *ngIf="name">, {{ name }}</span
      >! Protect your product from £{{
        preferredPaymentOption?.subsequentPayment || 0 | number: '1.2-2'
      }}
      a month.&nbsp;</span
    >
    <span *ngIf="isCard"
      >Want to avoid unexpected repair costs? Protect your product today.</span
    >
  </div>
</ui-banner>
