<ng-container *ngIf="href||routerLink; else button">
  <ng-container *ngIf="routerLink then LinkRouter;else LinkHref"></ng-container>
</ng-container>
<ng-template #LinkHref>
  <a
    [href]="href"
    class="btn btn--{{variant}} icon-align-{{iconAlign}}"
    [class.btn--with-icon]="icon"
    [class.btn--no-icon]="!icon"
    [class.btn--disabled]="disabled && disableMode==='visual'"
    [attr.disabled]="(disabled && disableMode==='disable')?'disabled':undefined"
    [attr.tabindex]="tabIndex"
  >
    <ng-container *ngTemplateOutlet="inner"></ng-container>
  </a>
</ng-template>
<ng-template #LinkRouter>
  <a
    [routerLink]="routerLink"
    class="btn btn--{{variant}} icon-align-{{iconAlign}}"
    [class.btn--with-icon]="icon"
    [class.btn--no-icon]="!icon"
    [class.btn--disabled]="disabled && disableMode==='visual'"
    [attr.disabled]="(disabled && disableMode==='disable')?'disabled':undefined"
    [attr.tabindex]="tabIndex"
  >
    <ng-container *ngTemplateOutlet="inner"></ng-container>
  </a>
</ng-template>
<ng-template #button>
  <button
    [type]="type"
    class="btn btn--{{variant}} icon-align-{{iconAlign}}"
    [class.btn--with-icon]="icon"
    [class.btn--no-icon]="!icon"
    [class.btn--disabled]="disabled && disableMode==='visual'"
    [attr.disabled]="(disabled && disableMode==='disable')?'disabled':undefined"
    [attr.tabindex]="tabIndex"
  >
    <ng-container *ngTemplateOutlet="inner"></ng-container>
  </button>
</ng-template>

<ng-template #inner>
  <ng-container *ngIf="icon; else justText">
    <span class="label">
      <ng-container *ngTemplateOutlet="projected"></ng-container>
    </span>
    <i class="{{iconSet}} {{iconSet}}-{{icon}}"></i>
  </ng-container>
  <ng-template #justText>
    <ng-container *ngTemplateOutlet="projected"></ng-container>
  </ng-template>
</ng-template>

<ng-template #projected>
  <ng-content></ng-content>
</ng-template>

