<div class="covers-exclusions">
  <ui-tabs
    (tabSelected)="handleTabSelection($event)"
    class="tab-view"
    *ngIf="displayAs === 'tabs'"
  >
    <ui-tab title="What's included">
      <ng-container *ngTemplateOutlet="whatIncluded" ;></ng-container>
    </ui-tab>
    <ui-tab title="What's not included" (click)="handleWhatsExcludedClick()">
      <ng-container *ngTemplateOutlet="whatExcluded" ;></ng-container>
    </ui-tab>
  </ui-tabs>
  <div *ngIf="displayAs === 'stacked'" class="stack-view">
    <h3>What's included</h3>
    <ng-container *ngTemplateOutlet="whatIncluded" ;></ng-container>
    <h3>What's not included</h3>
    <ng-container *ngTemplateOutlet="whatExcluded" ;></ng-container>
  </div>
</div>

<ng-template #whatIncluded>
  <ul class="list" [ngClass]="{ 'mb-1 py-1': displayAs === 'stacked' }">
    <li
      class="list__item list__item--icon-large-tick"
      *ngFor="let includedItem of included"
    >
      {{ includedItem }}
    </li>
  </ul>
</ng-template>

<ng-template #whatExcluded>
  <ul class="list" [ngClass]="{ 'pt-1 whatExcluded': displayAs === 'stacked' }">
    <li
      class="list__item list__item--icon-large-cross"
      *ngFor="let excludedItem of excluded"
    >
      {{ excludedItem }}
    </li>
  </ul>
</ng-template>
