import { AbstractControl } from '@angular/forms';
import { MonoTypeOperatorFunction, pipe } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import {
  disableControls,
  enableControls,
  getAllControls,
  getAllDisabledControls,
} from '../utils/form.utils';

export function enableControl(
  control: AbstractControl | null | undefined
): MonoTypeOperatorFunction<boolean> {
  let existingDisabledControls: AbstractControl[] = [];
  const controls = getAllControls(control);

  return pipe(
    filter((enabled: boolean) => enabled !== control?.enabled),
    tap((enabled: boolean) => {
      if (enabled) {
        enableControls(...controls);
        disableControls(...existingDisabledControls);
      } else {
        existingDisabledControls = getAllDisabledControls(control);
        disableControls(...controls);
      }
    })
  );
}
