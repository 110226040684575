<div class="appliance-details-form-header">
  <ng-container
    *ngIf="
      isMultiBasket && hasExistingWhiteGoodsApplianceQuote;
      else defaultHeader
    "
  >
    <h2 class="appliance-details-form-header__title">Add another appliance</h2>
    <p class="appliance-details-form-header__sub-title">
      <ng-content select="[multi-item-subtitle]"></ng-content>
    </p>
  </ng-container>
  <ng-template #defaultHeader>
    <h2 class="appliance-details-form-header__title">
      {{ initialFormHeaderTitle }}
    </h2>
    <p class="appliance-details-form-header__sub-title">
      <ng-content select="[single-item-subtitle]"></ng-content>
    </p>
  </ng-template>
</div>
