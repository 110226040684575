import { Component, Input, ViewEncapsulation } from '@angular/core';
import { NavigationLink } from '@domgen/dgx-fe-business-models';

@Component({
  selector: 'dgx-ui-wl-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Input() protectLinks: NavigationLink[] | undefined;

  @Input() usefulLinks: NavigationLink[] = [];

  @Input() legalLinks: NavigationLink[] = [];

  currentYear = new Date().getFullYear();
}
