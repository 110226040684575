import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CmsFormField } from '@domgen/dgx-fe-business-models';
import {
  ControlType,
  CustomValidatorService,
  TextInputDef,
} from '@domgen/dgx-fe-dynamic-form-builder';
import * as CmsHelpers from '@domgen/dgx-fe-business-components';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { PersonalDetailsFormField } from '@common/util-models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'oren-missing-email-address',
  templateUrl: './missing-email-address.component.html',
  styleUrls: ['./missing-email-address.component.scss'],
})
export class MissingEmailAddressComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  public formGroup: FormGroup;
  field = {} as TextInputDef;
  private valueChangeSubscriptions = new Subscription();
  @Input() set emailData(value: CmsFormField | undefined) {
    if (value) {
      this.getEmailField(value);
    }
  }
  @Input() initialValue?: string;
  @Input() validateField = false;
  @Output() valueChanges: EventEmitter<string> = new EventEmitter();
  @Output() form = new EventEmitter<FormGroup>();

  constructor(private customValidatorService: CustomValidatorService) {
    this.formGroup = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        this.customValidatorService.emailValidator,
      ]),
    });

    this.valueChangeSubscriptions = this.formGroup.valueChanges
      .pipe(
        map((change) => change?.email),
        distinctUntilChanged()
      )
      .subscribe((email) => {
        this.valueChanges.emit(email);
        this.form.emit(this.formGroup);
      });
  }

  ngOnInit(): void {
    if (this.initialValue) {
      this.formGroup.patchValue({
        email: this.initialValue,
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.validateField?.currentValue === true) {
      this.formGroup.markAllAsTouched();
    }
  }

  ngAfterViewInit() {
    this.form.emit(this.formGroup);
  }

  private getEmailField(cmsFormField: CmsFormField) {
    const defaultFieldDef = CmsHelpers.getDefaultFieldDef(
      cmsFormField,
      PersonalDetailsFormField.Email
    );

    this.field = {
      ...defaultFieldDef,
      controlType: ControlType.INPUT,
      validators: [
        Validators.required,
        this.customValidatorService.emailValidator,
      ],
    } as TextInputDef;
  }

  ngOnDestroy() {
    if (this.valueChangeSubscriptions) {
      this.valueChangeSubscriptions.unsubscribe();
    }
  }
}
