import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { addNumberSubsequentPaymentsToQuoteResponse } from '@common/data-access-quotes';
import { BuildConfigService } from '@common/util-foundation';
import {
  CORRELATION_ID_HEADER,
  CreateQuoteRequest,
  CreateQuoteResponse,
  GetOfferRenewalQuoteRequest,
} from '@common/util-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class OfferRenewalApiService {
  constructor(
    private httpClient: HttpClient,
    private buildConfigService: BuildConfigService
  ) {}

  public createOfferRenewalQuote(
    createQuoteParams: CreateQuoteRequest | GetOfferRenewalQuoteRequest
  ): Observable<{
    quoteResponse: CreateQuoteResponse;
    correlationId: string | null;
  }> {
    const config = this.buildConfigService.config;
    const createQuoteUrl = `${config.basketApi}/quote`;
    return this.httpClient
      .post<{ result: CreateQuoteResponse }>(
        createQuoteUrl,
        createQuoteParams,
        {
          observe: 'response',
          headers: {
            ...this.getHeaders(),
          },
        }
      )
      .pipe(
        map((response: HttpResponse<{ result: CreateQuoteResponse }>) => {
          const quoteResponse = response.body?.result as CreateQuoteResponse;
          const correlationId = response.headers.get(CORRELATION_ID_HEADER);

          return {
            quoteResponse,
            correlationId,
          };
        }),
        map(
          (response: {
            quoteResponse: CreateQuoteResponse;
            correlationId: string | null;
          }) => ({
            ...response,
            quoteResponse: addNumberSubsequentPaymentsToQuoteResponse(
              response.quoteResponse
            ),
          })
        )
      );
  }

  private getHeaders() {
    return {
      'request-source': this.buildConfigService.config.catalogueStore,
      'request-action': this.buildConfigService.config.action,
    };
  }
}
