<div class="powered-by" *ngIf="clientLogoPath">
  <span class="mr-1">Powered by</span> <a
    href="https://www.domesticandgeneral.com/"
    target="_blank"
    class="powered-by__logo xs--middle flex-auto"
    (click)="onClick($event)"
  >
    <img
      src="assets/images/dg-logo-colour.png"
      role="img"
      alt="Domestic & General"
    />
  </a>
</div>
