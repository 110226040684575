import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QuotesActions } from '@common/data-access-quotes';
import {
  CorrelationIdActions,
  PersonalDetailsSharedActions,
} from '@common/data-access-shared';
import { PersonalDetailsFormSubmitType } from '@common/util-models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, filter, map, tap } from 'rxjs/operators';
import { OfferRenewalApiService } from '../services/offer-renewal-api.service';
import * as OfferRenewalActions from './offer-renewal.actions';
import { OfferRenewalErrorService } from '@oren/util-foundation';
import { SelectPaymentActions } from '@common/data-access-select-payment';

@Injectable()
export class OfferRenewalEffects {
  getOfferQuote$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OfferRenewalActions.getOfferQuote,
        OfferRenewalActions.getEmailOfferQuote,
        OfferRenewalActions.getRedirectOfferQuote
      ),
      exhaustMap((action) => {
        return this.offerRenewalApiService
          .createOfferRenewalQuote(action.offerQuoteRequest)
          .pipe(
            map(({ quoteResponse, correlationId }) => {
              return OfferRenewalActions.getOfferQuoteSuccess({
                quoteResponse,
                correlationId,
              });
            }),
            catchError((error: HttpErrorResponse) => {
              return action.type ===
                OfferRenewalActions.getRedirectOfferQuote.type
                ? of(
                    OfferRenewalActions.getRedirectOfferQuoteFailure({ error })
                  )
                : of(OfferRenewalActions.getOfferQuoteFailure({ error }));
            })
          );
      })
    )
  );

  getPlanRenewalQuote$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OfferRenewalActions.getPlanRenewalQuote,
        OfferRenewalActions.getEmailPlanRenewalQuote,
        OfferRenewalActions.getRedirectPlanRenewalQuote
      ),
      exhaustMap((action) => {
        return this.offerRenewalApiService
          .createOfferRenewalQuote(action.planRenewalQuoteRequest)
          .pipe(
            map(({ quoteResponse, correlationId }) => {
              return OfferRenewalActions.getPlanRenewalQuoteSuccess({
                quoteResponse,
                correlationId,
              });
            }),
            catchError((error: HttpErrorResponse) => {
              return action.type ===
                OfferRenewalActions.getRedirectPlanRenewalQuote.type
                ? of(
                    OfferRenewalActions.getRedirectPlanRenewalQuoteFailure({
                      error,
                    })
                  )
                : of(
                    OfferRenewalActions.getPlanRenewalQuoteFailure({
                      error,
                    })
                  );
            })
          );
      })
    )
  );

  createQuote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OfferRenewalActions.getOfferQuote,
        OfferRenewalActions.getPlanRenewalQuote,
        OfferRenewalActions.getRedirectOfferQuote,
        OfferRenewalActions.getRedirectPlanRenewalQuote,
        OfferRenewalActions.getEmailOfferQuote,
        OfferRenewalActions.getEmailPlanRenewalQuote
      ),
      map(() => QuotesActions.createQuote())
    )
  );

  createQuoteSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OfferRenewalActions.getOfferQuoteSuccess,
        OfferRenewalActions.getPlanRenewalQuoteSuccess
      ),
      map(({ quoteResponse }) => {
        if (quoteResponse.basket?.items[0]?.data?.quotes?.length > 1) {
          return OfferRenewalActions.multiQuoteError();
        } else {
          return QuotesActions.createQuoteSuccess({
            quoteApiResult: quoteResponse,
          });
        }
      })
    )
  );

  selectFirstPaymentType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuotesActions.createQuoteSuccess),
      map(({ quoteApiResult }) => {
        return SelectPaymentActions.selectFirstOrPreferredPaymentOption({
          quoteResponse: quoteApiResult,
        });
      })
    )
  );

  updatePersonalDetailsState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OfferRenewalActions.getOfferQuoteSuccess,
        OfferRenewalActions.getPlanRenewalQuoteSuccess
      ),
      map(({ quoteResponse }) =>
        PersonalDetailsSharedActions.setPersonalDetails({
          payload: ({
            address: {
              line1: quoteResponse?.customer?.address?.addressLine1,
              line2: quoteResponse?.customer?.address?.addressLine2,
              line3: '',
              postcode: quoteResponse?.customer?.address?.postalCode,
              county: quoteResponse?.customer?.address?.addressLine4,
            },
            email: quoteResponse?.customer?.email?.main ?? '',
            firstName: quoteResponse?.customer?.firstName,
            lastName: quoteResponse?.customer?.surname,
            mobileNumber: quoteResponse?.customer?.telephone?.mobile ?? '',
            title: quoteResponse?.customer?.title,
          } as unknown) as PersonalDetailsFormSubmitType,
        })
      )
    )
  );

  createQuoteFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OfferRenewalActions.getOfferQuoteFailure,
        OfferRenewalActions.getPlanRenewalQuoteFailure,
        OfferRenewalActions.getRedirectOfferQuoteFailure,
        OfferRenewalActions.getRedirectPlanRenewalQuoteFailure
      ),
      map(({ error }) =>
        QuotesActions.createQuoteFailure({
          error,
        })
      )
    )
  );

  setCorrelationId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OfferRenewalActions.getOfferQuoteSuccess,
        OfferRenewalActions.getPlanRenewalQuoteSuccess
      ),
      filter(({ correlationId }) => !!correlationId),
      map(({ correlationId }) =>
        CorrelationIdActions.setCorrelationID({
          correlationId,
        })
      )
    )
  );

  handleGetOfferError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OfferRenewalActions.getOfferQuoteFailure),
        tap(({ error }) => {
          this.errorService.handleHttpError(error, 'offer');
        })
      ),
    { dispatch: false }
  );

  handleGetRenewalError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OfferRenewalActions.getPlanRenewalQuoteFailure),
        tap(({ error }) => {
          this.errorService.handleHttpError(error, 'renewal');
        })
      ),
    { dispatch: false }
  );

  handleMultiQuoteError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OfferRenewalActions.multiQuoteError),
        tap(() => {
          this.errorService.handleMultiQuoteError();
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly errorService: OfferRenewalErrorService,
    private readonly offerRenewalApiService: OfferRenewalApiService
  ) {}
}
