<ui-panel variant="highlight-feedback">
  <ui-panel-header>
    <ui-panel-title
      >Protection for your {{ brand }} {{ applianceName }}</ui-panel-title
    >
  </ui-panel-header>
  <ui-panel-body class="detail-panel" *ngIf="detail">
    <ui-panel-display-info-row
      *ngFor="let row of detail"
      [data]="row"
    ></ui-panel-display-info-row>
    <div class="label">How long does the {{ planOrPolicy }} last?</div>
    <div class="value">
      <ng-container
        *ngIf="paidByDirectDebit && isTermPlan"
        [ngTemplateOutlet]="paidByDirectDebitTmp"
        [ngTemplateOutletContext]="{
          periodOfCover: planDetails?.periodOfCover,
          planOrPolicy: planOrPolicy
        }"
      ></ng-container>
      <ng-container
        *ngIf="!paidByDirectDebit && isTermPlan"
        [ngTemplateOutlet]="paidByCardTmp"
        [ngTemplateOutletContext]="{
          periodOfCover: planDetails?.periodOfCover,
          planOrPolicy: planOrPolicy
        }"
      ></ng-container>
      <ng-container
        *ngIf="!isTermPlan"
        [ngTemplateOutlet]="nonTermPlansTmp"
        [ngTemplateOutletContext]="{
          planOrPolicy: planOrPolicy
        }"
      ></ng-container>
    </div>

    <ng-content></ng-content>

    <ui-panel-content-row id="terms-link">
      <ng-container *ngIf="terms">
        <br />
        <a class="link" target="_blank" [href]="terms?.link"
          >Terms and Conditions</a
        >
      </ng-container>
      <ng-container *ngIf="productInfo">
        <br />
        <a class="link" target="_blank" [href]="productInfo?.link"
          >Insurance Product Information Document</a
        >
      </ng-container>
    </ui-panel-content-row>

    <ng-template
      #paidByDirectDebitTmp
      let-planOrPolicy="planOrPolicy"
      let-periodOfCover="periodOfCover"
    >
      The {{ planOrPolicy }} lasts for {{ periodOfCover }} months. Most
      {{ planOrPolicy === 'plan' ? 'plans' : 'policies' }} will be automatically
      renewed each year when paid for with Direct Debit. However, we recommend
      you refer to the documentation that we sent you to find out more about
      your {{ planOrPolicy === 'plan' ? 'plan’s' : 'policy’s' }} duration and
      how it renews.
    </ng-template>

    <ng-template
      #paidByCardTmp
      let-planOrPolicy="planOrPolicy"
      let-periodOfCover="periodOfCover"
    >
      The {{ planOrPolicy }} lasts for {{ periodOfCover }} months.
    </ng-template>

    <ng-template #nonTermPlansTmp let-planOrPolicy="planOrPolicy">
      The {{ planOrPolicy }} continues for as long as you like with monthly
      Direct Debit payments.
    </ng-template>
  </ui-panel-body>
</ui-panel>
