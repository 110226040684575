import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InterpolatePipe } from './pipes/interpolate.pipe';

@NgModule({
  imports: [CommonModule],
  exports: [InterpolatePipe],
  declarations: [InterpolatePipe],
})
export class CommonUtilFoundationModule {}
