import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  getCheckoutPersonalDetailsFormValue,
  getEmailAddress,
} from './checkout.selectors';
import {
  checkoutPersonalDetailsFormSubmit,
  checkoutSelectPaymentFormSubmit,
  checkoutDirectDebitFormSubmit,
  restart,
  checkoutSelectPaymentOption,
} from './checkout.actions';
import {
  CheckoutPersonalDetailsFormValue,
  CheckoutSelectPaymentFormValue,
} from '@oren/util-foundation';
import { DirectDebit, PaymentType } from '@common/util-models';

@Injectable()
export class CheckoutFacade {
  checkoutPersonalDetailsFormValue$: Observable<CheckoutPersonalDetailsFormValue> = this.store.select(
    getCheckoutPersonalDetailsFormValue
  );

  emailAddress$: Observable<string> = this.store.select(getEmailAddress);

  constructor(private store: Store) {}

  checkoutPersonalDetailsFormSubmit(
    formValue: CheckoutPersonalDetailsFormValue
  ): void {
    this.store.dispatch(checkoutPersonalDetailsFormSubmit({ formValue }));
  }

  checkoutSelectPaymentFormSubmit(
    formValue: CheckoutSelectPaymentFormValue
  ): void {
    this.store.dispatch(checkoutSelectPaymentFormSubmit({ formValue }));
  }

  checkoutDirectDebitFormSubmit(formValue: DirectDebit): void {
    this.store.dispatch(checkoutDirectDebitFormSubmit({ formValue }));
  }

  selectPaymentOption(paymentType: PaymentType) {
    this.store.dispatch(checkoutSelectPaymentOption({ paymentType }));
  }

  restart(): void {
    this.store.dispatch(restart());
  }
}
