import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'dgx-ui-wl-header-powered-by',
  templateUrl: './header-powered-by.component.html',
  styleUrls: ['./header-powered-by.component.scss'],
})
export class HeaderPoweredByComponent {
  /**
   * Receives a link to a clients logo, if required and it will render the powered by logo
   */
  @Input() clientLogoPath: string | undefined;

  /**
   * Emits a click event back to the parent component
   */
  @Output() clicked: EventEmitter<MouseEvent> = new EventEmitter();

  /**
   * Tracks menu open state
   */
  isOpen = false;

  /**
   * Listens and fires emit `clicked` when clicked
   */
  onClick(e: MouseEvent) {
    this.clicked.emit(e);
    this.isOpen = false;
  }
}
