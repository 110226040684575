import { HttpClientModule } from '@angular/common/http';
import { Inject, NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonDataAccessApplianceDetailsModule } from '@common/data-access-appliance-details';
import { CommonDataAccessCardPaymentModule } from '@common/data-access-card-payment';
import { CommonDataAccessCheckoutModule } from '@common/data-access-checkout';
import { CommonDataAccessCheckoutOrderConfirmationModule } from '@common/data-access-checkout-order-confirmation';
import { CommonDataAccessFeatureConfigModule } from '@common/data-access-feature-config';
import { CommonDataAccessPersonalDetailsModule } from '@common/data-access-personal-details';
import { CommonDataAccessQuotesModule } from '@common/data-access-quotes';
import { CommonDataAccessSelectPaymentModule } from '@common/data-access-select-payment';
import { CommonDataAccessUserProfileModule } from '@common/data-access-user-profile';
import { FORM_BUILDER_CONFIG } from '@common/util-foundation';
import { SharedFeatureAuthenticationModule } from '@domgen/dgx-fe-auth';
import { SharedUiCmsModule } from '@domgen/dgx-fe-content-management';
import { SharedUiDynamicFormBuilderModule } from '@domgen/dgx-fe-dynamic-form-builder';
import { RecaptchaV3Module } from 'ng-recaptcha';

import { AppProvidersConfigToken } from './modules/app-providers-config.token';
import { authenticationModuleConfig } from './modules/authentication-module.config';
import { cmsConfigToken } from './modules/cms-config.token';
import { AppProvidersConfig, PROVIDERS } from './modules/providers';
import { SharedDataAccessAuthModule } from '@shared/data-access-auth';
import { CommonDataAccessGaTaggingModule } from '@common/data-access-ga-tagging';
import { CommonDataAccessCheckoutBasketModule } from '@common/data-access-checkout-basket';


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedUiCmsModule.forRoot(cmsConfigToken),
    SharedUiDynamicFormBuilderModule.forRoot(FORM_BUILDER_CONFIG),
    SharedFeatureAuthenticationModule.forRoot(authenticationModuleConfig),
    CommonDataAccessCheckoutBasketModule,
    CommonDataAccessCheckoutModule,
    CommonDataAccessCheckoutOrderConfirmationModule,
    CommonDataAccessUserProfileModule,
    CommonDataAccessPersonalDetailsModule,
    CommonDataAccessSelectPaymentModule,
    CommonDataAccessCardPaymentModule,
    CommonDataAccessQuotesModule,
    CommonDataAccessApplianceDetailsModule,
    CommonDataAccessFeatureConfigModule,
    CommonDataAccessGaTaggingModule,
    SharedDataAccessAuthModule,
    RecaptchaV3Module,
  ],
  providers: [...PROVIDERS],
})
export class CommonFeatureCoreModule {
  constructor(
    @Optional() @SkipSelf() commonFeatureCoreModule: CommonFeatureCoreModule,
    @Optional()
    @Inject(AppProvidersConfigToken)
    appProvidersConfig: AppProvidersConfig
  ) {
    if (commonFeatureCoreModule) {
      throw new TypeError(`CommonFeatureCoreModule can be imported only once`);
    }
    if (!appProvidersConfig) {
      throw new TypeError(
        'CommonFeatureCoreModule requires AppProvidersConfig'
      );
    }
  }
}
