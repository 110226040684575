import { Injectable } from '@angular/core';
import { FeatureConfigState } from '@common/data-access-feature-config';
import { filterNullUndefined } from '@common/util-foundation';
import {
  AvailableCover,
  Basket,
  BasketItem,
  ContractType,
  Cover,
  CoverType,
  ExcessPrice,
  Quote,
  SelectedBasketItem,
  TaggingMetadata,
  UpgradeCoverConfig,
} from '@common/util-models';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as QuotesPageSelectors from '../+state/quotes-page.selectors';
import * as QuotesActions from '../+state/quotes.actions';
import { QuotesPartialState } from '../+state/quotes.reducer';
import * as QuotesSelectors from '../+state/quotes.selectors';

@Injectable()
export class QuotesFacade {
  basket$: Observable<Basket | undefined> = this.store.select(
    QuotesSelectors.getBasket
  );

  selectedItems$: Observable<SelectedBasketItem[]> = this.store.select(
    QuotesSelectors.getSelectedItems
  );

  quoteRemoteStateLoading$: Observable<boolean> = this.store.pipe(
    select(QuotesSelectors.getQuotesRemoteStateLoading)
  );

  quoteRemoteStateUpdating$: Observable<boolean> = this.store.pipe(
    select(QuotesSelectors.getQuotesRemoteStateUpdating)
  );

  quoteRemoteStateUnstable$: Observable<boolean> = this.store.pipe(
    select(QuotesSelectors.getQuotesRemoteStateUnstable)
  );

  quote$: Observable<Quote> = this.store.pipe(
    select(QuotesPageSelectors.getQuote),
    filterNullUndefined()
  );

  multiItemQuotes$: Observable<Quote[]> = this.store.pipe(
    select(QuotesPageSelectors.getMultiItemQuotes)
  );

  hasExistingHeatingApplianceQuote$: Observable<boolean> = this.store.pipe(
    select(QuotesPageSelectors.hasExistingHeatingApplianceQuote)
  );

  hasExistingWhiteGoodsApplianceQuote$: Observable<boolean> = this.store.pipe(
    select(QuotesPageSelectors.hasExistingWhiteGoodsApplianceQuote)
  );

  currentItem$: Observable<BasketItem | undefined> = this.store.select(
    QuotesSelectors.getCurrentItem
  );

  currentItemId$: Observable<string | undefined> = this.store.select(
    QuotesSelectors.getCurrentItemId
  );

  currentItemFirstQuoteAsSelectedBasketItem$: Observable<
    SelectedBasketItem | undefined
  > = this.store.select(
    QuotesSelectors.getCurrentItemFirstQuoteAsSelectedBasketItem
  );

  basketId$ = this.store.pipe(
    select(QuotesSelectors.getBasketId),
    filterNullUndefined()
  );

  hasBasket$ = this.store.pipe(
    select(QuotesSelectors.getBasketId),
    map((basketId) => !!basketId)
  );

  taggingMetadata$: Observable<TaggingMetadata> = this.store.pipe(
    select(QuotesPageSelectors.getTaggingMetadata),
    filterNullUndefined()
  );

  availableExcessPrices$: Observable<ExcessPrice[]> = this.store.pipe(
    select(QuotesPageSelectors.getAvailableExcessPrices),
    filter((excess) => !!excess?.length)
  );

  availableCovers$: Observable<AvailableCover[]> = this.store.pipe(
    select(QuotesPageSelectors.getAvailableCovers),
    filter((covers) => !!covers?.length)
  );

  selectedExcess$: Observable<number> = this.store.pipe(
    select(QuotesSelectors.getSelectedExcess)
  );

  selectedCoverType$: Observable<CoverType> = this.store.pipe(
    select(QuotesSelectors.getSelectedCoverType),
    filter((selectedCoverType) => !!selectedCoverType)
  );

  selectedCoverName$: Observable<string> = this.store.pipe(
    select(QuotesSelectors.getSelectedCoverName)
  );

  selectedContractType$: Observable<ContractType | null> = this.store.pipe(
    select(QuotesPageSelectors.getSelectedContractType)
  );

  isInsurance$: Observable<boolean> = this.store.pipe(
    select(QuotesPageSelectors.getIsInsurance),
    map((isInsurance) => !!isInsurance)
  );

  selectedCover$: Observable<Cover | undefined> = this.store.pipe(
    select(QuotesSelectors.getSelectedCover)
  );

  isMultiplePaymentOptions$: Observable<boolean> = this.selectedCover$.pipe(
    map(
      (cover) =>
        !!(cover?.paymentOptions.card && cover?.paymentOptions.directDebit)
    )
  );

  isMultipleExcessAvailable$: Observable<boolean> = this.availableExcessPrices$.pipe(
    map((excess) => !!(excess?.length && excess.length > 1))
  );

  alternateProduct$: Observable<
    UpgradeCoverConfig | undefined
  > = this.store.pipe(select(QuotesPageSelectors.getAlternateProduct));

  periodOfCover$: Observable<number> = this.store.pipe(
    select(QuotesSelectors.getPeriodOfCover),
    filterNullUndefined()
  );

  isHeating$: Observable<boolean> = this.store.pipe(
    select(QuotesSelectors.getCurrentItemIsHeatingAppliance)
  );

  unusedItemIds$: Observable<string[]> = this.store.pipe(
    select(QuotesSelectors.getUnusedItemIds)
  );

  mostRecentActionMessage$: Observable<string | undefined> = this.store.pipe(
    select(QuotesSelectors.getMostRecentActionMessage)
  );

  constructor(private store: Store<QuotesPartialState & FeatureConfigState>) {}

  /** Single item basket action */
  selectExcess(excessValue: number): void {
    this.store.dispatch(QuotesActions.selectExcessValue({ excessValue }));
  }

  /** Single item basket action */
  selectCoverType(coverType: CoverType): void {
    this.store.dispatch(QuotesActions.selectCoverType({ coverType }));
  }

  /** Single item basket action */
  selectFirstCoverType(): void {
    this.store.dispatch(QuotesActions.selectFirstCoverType());
  }

  /** Single item basket action */
  autoSelectCoverType(coverType: CoverType): void {
    this.store.dispatch(QuotesActions.autoSelectCoverType({ coverType }));
  }

  /** Single item basket action */
  setInitialSelectCoverType(coverType: CoverType): void {
    this.store.dispatch(QuotesActions.setInitialSelectCoverType({ coverType }));
  }

  /** Single item basket action */
  autoSelectFromRouteParams(coverType: CoverType, excess: number): void {
    this.store.dispatch(
      QuotesActions.autoSelectFromRouteParams({ coverType, excess })
    );
  }

  /** Multi item basket action */
  addToSelectedItems(selectedItem: SelectedBasketItem) {
    this.store.dispatch(QuotesActions.addToSelectedItems({ selectedItem }));
  }

  /** Multi item basket action */
  replaceSelectedItemsWithSingleItem(selectedItem: SelectedBasketItem) {
    this.store.dispatch(
      QuotesActions.replaceSelectedItemsWithSingleItem({ selectedItem })
    );
  }

  /** Multi item basket action */
  removeSelectedItem(selectedItem: SelectedBasketItem) {
    this.store.dispatch(QuotesActions.removeSelectedItem({ selectedItem }));
  }

  /** Multi item basket action */
  removeCurrentItem() {
    this.store.dispatch(QuotesActions.removeCurrentItem());
  }

  proceedToQuote() {
    this.store.dispatch(QuotesActions.proceedToQuote());
  }

  setCurrentItemIdToFirstSelectedItem(): void {
    this.store.dispatch(QuotesActions.setCurrentItemIdToFirstSelectedItem());
  }
}
