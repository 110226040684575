<a
  *ngIf="data?.routerPath; else externalNavigationLink"
  (click)="onClick($event)"
  [routerLink]="[data?.routerPath]"
  [ngClass]="{'with-icon': data?.icon}"
  [attr.rel]="data?.newWindow?'noopener noreferrer':undefined"
  [attr.target]="data?.newWindow?'_blank':undefined"
  data-automation="navigationLink">
  <span
    *ngIf="data?.icon"
    [ngClass]="data?.icon ? ('icon-'+data?.icon) : ''"
    class="icon icon--xl"
  ></span>
  <span
    [ngClass]="{'icon-label': data?.icon}"
  >{{data?.label}}</span>
</a>

<ng-template #externalNavigationLink>
  <a
    (click)="onClick($event)"
    [attr.rel]="data?.newWindow?'noopener noreferrer':undefined"
    [attr.target]="data?.newWindow?'_blank':undefined"
    [attr.href]="data?.href"
    [ngClass]="{'with-icon': data?.icon}"
    data-automation="navigationLink">
    <span
      *ngIf="data?.icon"
      [ngClass]="data?.icon ? ('icon-'+data?.icon) : ''"
      class="icon icon--xl"
    ></span>
    <span
      [ngClass]="{'icon-label': data?.icon}"
    >{{data?.label}}</span>
  </a>
</ng-template>
