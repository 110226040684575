<div class="go-to-my-account-section">
    <div class="md-row sm-col">
      <div class="xs-12 md-12 lg-7 no-gutter p-4">
        <!-- Outer wrapper -->
        <div *ngIf="content">
          <h2 class="title">
            {{ content.title }}
          </h2>
          <div class="md-row summary">
            {{ content.summary }}
          </div>
        </div>
        <div>
          <ui-button-group>
            <ui-button
              [icon]="'arrow-right'"
              (click)="redirectToMyAccount()"
            >
            Go to My Account
            </ui-button>
          </ui-button-group>
        </div>
      </div>
      <!-- Banner -->
      <div class="xs-12 md-12 lg-5 no-gutter">
        <img
          class="banner"
          alt="{{ content?.title }}"
          src="./assets/images/img-couple-viewing-laptop-440x344.jpg"
        />
      </div>
    </div>
  </div>
  
