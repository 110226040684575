import { DirectDebit, PaymentType } from '@common/util-models';
import { createAction, props } from '@ngrx/store';
import {
  CheckoutPersonalDetailsFormValue,
  CheckoutSelectPaymentFormValue,
} from '@oren/util-foundation';

export const checkoutPersonalDetailsFormSubmit = createAction(
  '[CheckoutPersonalDetailsComponent] Checkout Personal Details Form Submit',
  props<{ formValue: CheckoutPersonalDetailsFormValue }>()
);

export const checkoutSelectPaymentFormSubmit = createAction(
  '[CheckoutSelectPaymentComponent] Store Checkout Select Payment Form Submit',
  props<{ formValue: CheckoutSelectPaymentFormValue }>()
);

export const checkoutSelectPaymentOption = createAction(
  '[CheckoutSelectPaymentComponent] Change payment option',
  props<{ paymentType: PaymentType }>()
);

export const checkoutDirectDebitFormSubmit = createAction(
  '[CheckoutDirectDebitComponent] Store Checkout Direct Debit Form Submit',
  props<{ formValue: DirectDebit }>()
);

export const restart = createAction(
  '[CheckoutDirectDebitComponent] Redirect the user to offer/renewal page'
);
