<ng-container *ngIf="cmsData">
  <div class="covers-exclusions">
    <div class="stack-view">
      <h2>{{ cmsData.title }}</h2>
      <ul class="list mb-1 py-1">
        <li
          class="list__item list__item--icon-large-tick"
          *ngFor="let includedItem of cmsData.included"
        >
          {{ includedItem }}
        </li>
      </ul>
    </div>
  </div>
</ng-container>
