<footer>
  <div class="footer__main container">
    <div class='links xs-12'>
      <div class='links__group'>
        <ng-container *ngIf="protectLinks">
          <div class='links__col'>
            <h4>Products we protect</h4>
            <ul class="mb-2">
              <li *ngFor='let link of protectLinks'>
                <ng-container
                  [ngTemplateOutlet]="footerNavigationLink"
                  [ngTemplateOutletContext]="{
                  navigationLink: link
                }"
                ></ng-container>
              </li>
            </ul>
          </div>
          <div class='links__col--separator'></div>
        </ng-container>
        <div class='links__col'>
          <h4>Useful links</h4>
          <ul class="links__columns">
            <li *ngFor='let link of usefulLinks'>
              <ng-container
                [ngTemplateOutlet]="footerNavigationLink"
                [ngTemplateOutletContext]="{
                navigationLink: link
              }"
              ></ng-container>
            </li>
            <li *ngIf='usefulLinks.length%2' class="empty-list"></li>
          </ul>
        </div>
      </div>
    </div>
    <div class='footer__disclaimers xs-12'>
      <ng-content></ng-content>
    </div>
  </div>
  <div class="footer__legal">
    <div class="container">
      <div class="xs-12 footer__legal-inner">
        <div class="footer__copyright">&copy; {{ currentYear }} Domestic & General Group Limited</div>
        <div class='footer__legal-filler'></div>
        <div class='footer__legal-links'>
          <ng-container *ngFor='let link of legalLinks'>
            <ng-container
              [ngTemplateOutlet]="footerNavigationLink"
              [ngTemplateOutletContext]="{
                navigationLink: link
              }"
            ></ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</footer>
<ng-template #footerNavigationLink let-navigationLink="navigationLink">
  <a
    *ngIf="navigationLink.routerPath; else externalFooterNavigationLink"
    [routerLink]="[navigationLink.routerPath]"
  >
    {{ navigationLink.label }}
  </a>

  <ng-template #externalFooterNavigationLink>
    <a [href]="navigationLink.href"
       [attr.rel]="navigationLink.newWindow?'noopener noreferrer':undefined"
       [attr.target]="navigationLink.newWindow?'_blank':undefined">
      {{ navigationLink.label }}
    </a>
  </ng-template>
</ng-template>
