<div class="overlay lg--hidden" *ngIf="isOpen" [@fadeInOut]></div>
<header
  data-hook="app-header"
  [ngClass]="{ 'header--white-label': clientLogoPath }"
  class="header xs--middle"
  [class.active]="isOpen"
>
  <div class="container">
    <div class="xs--middle xs-12 header__nav-wrapper">
      <a
        *ngIf="
          navigationLinks.home?.routerPath;
          else externalHomeNavigationLink
        "
        (click)="onClick($event,'Logo')"
        [routerLink]="[navigationLinks.home?.routerPath]"
        class="header__logo xs--middle flex-auto"
      >
        <img
          src="{{
            clientLogoPath ? clientLogoPath : 'assets/images/dg-logo-colour.png'
          }}"
          role="img"
          [alt]="navigationLinks.home?.label"
        />
      </a>

      <ng-template #externalHomeNavigationLink>
        <a
          (click)="onClick($event,'Logo')"
          [href]="navigationLinks.home?.href"
          class="header__logo xs--middle flex-auto"
          target="_blank"
        >
          <img
            src="{{
              clientLogoPath
                ? clientLogoPath
                : 'assets/images/dg-logo-colour.png'
            }}"
            role="img"
            [alt]="navigationLinks.home?.label"
          />
        </a>
      </ng-template>

      <nav class="header__nav flex-auto">
        <div
          class="nav__link--list xs--hidden xs--stack lg-row"
          [class.active]="isOpen"
        >
          <dgx-ui-wl-header-nav-link
            [data]="navigationLinks.products"
            *ngIf="!navigationLinks.products?.isHidden"
            (clicked)="onClick($event,navigationLinks.products?.label)"
          ></dgx-ui-wl-header-nav-link>
          <dgx-ui-wl-header-nav-link
            [data]="navigationLinks.repair"
            *ngIf="!navigationLinks.repair?.isHidden"
            (clicked)="onClick($event,navigationLinks.repair?.label)"
          ></dgx-ui-wl-header-nav-link>
          <dgx-ui-wl-header-nav-link
            [data]="navigationLinks.faq"
            *ngIf="!navigationLinks.faq?.isHidden"
            (clicked)="onClick($event,navigationLinks.faq?.label)"
          ></dgx-ui-wl-header-nav-link>
        </div>

        <div class="header__filler"></div>

        <dgx-ui-wl-header-powered-by
          [clientLogoPath]="clientLogoPath"
          (clicked)="onClick($event,'D+G Logo')"
          class="mr-2 lg-mr-0"
        ></dgx-ui-wl-header-powered-by>

        <div class="header__secondary-nav">
          <div class="secondary-nav__icon--box">
            <dgx-ui-wl-header-nav-link
              [data]="navigationLinks.profile"
              *ngIf="!navigationLinks.profile?.isHidden"
              (clicked)="onClick($event,navigationLinks.profile?.label)"
            ></dgx-ui-wl-header-nav-link>
            <dgx-ui-wl-header-nav-link
              class="hide--label"
              [data]="navigationLinks.basket"
              *ngIf="!navigationLinks.basket?.isHidden"
              (clicked)="onClick($event,navigationLinks.basket?.label)"
            ></dgx-ui-wl-header-nav-link>
          </div>
          <button
            id="menu-button"
            [class.active]="isOpen"
            class="header__menu-button lg--hidden"
            (click)="onToggleMenu()"
          ></button>
        </div>
      </nav>
    </div>
  </div>
</header>
