import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedUiDynamicFormBuilderModule } from '@domgen/dgx-fe-dynamic-form-builder';
import { OfferRenewalFormComponent } from './offer-renewal-form/offer-renewal-form.component';
import { LandingHeaderComponent } from './landing-header/landing-header.component';
import { DontMissOutAreaComponent } from './dont-miss-out-area/dont-miss-out-area.component';
import { SharedUiBaseComponentsModule } from '@domgen/dgx-fe-base-components';
import { MissingEmailAddressComponent } from './missing-email-address/missing-email-address.component';
import { CoverDurationSummaryComponent } from './cover-duration-summary/cover-duration-summary.component';
import { CommonUtilFoundationModule } from '@common/util-foundation';
import { NeedToKnowComponent } from './need-to-know/need-to-know.component';
import { QuoteCoverInclusionsComponent } from './quote-cover-inclusions/quote-cover-inclusions.component';
import { GoToMyAccountComponent } from './go-to-my-account/go-to-my-account.component';
import { OrenAppliancePlanDetailComponent } from './appliance-plan-detail/appliance-plan-detail.component';
import { OrenBannerComponent } from './banner/banner.component';
import { OrenCustomerDetailsComponent } from './customer-details/customer-details.component';

@NgModule({
  imports: [
    CommonModule,
    SharedUiBaseComponentsModule,
    CommonUtilFoundationModule,
    ReactiveFormsModule,
    SharedUiDynamicFormBuilderModule,
  ],
  declarations: [
    OfferRenewalFormComponent,
    LandingHeaderComponent,
    DontMissOutAreaComponent,
    MissingEmailAddressComponent,
    CoverDurationSummaryComponent,
    NeedToKnowComponent,
    QuoteCoverInclusionsComponent,
    GoToMyAccountComponent,
    OrenBannerComponent,
    OrenAppliancePlanDetailComponent,
    OrenCustomerDetailsComponent,
  ],
  exports: [
    OfferRenewalFormComponent,
    LandingHeaderComponent,
    DontMissOutAreaComponent,
    MissingEmailAddressComponent,
    CoverDurationSummaryComponent,
    NeedToKnowComponent,
    QuoteCoverInclusionsComponent,
    GoToMyAccountComponent,
    OrenBannerComponent,
    OrenAppliancePlanDetailComponent,
    OrenCustomerDetailsComponent,
  ],
})
export class OrenUiComponentsModule {}
