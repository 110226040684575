<div class="overlay" *ngIf="isOpen" [@fadeInOut]></div>
<header
  [ngClass]="{'header--white-label':clientLogoPath}"
  class="header xs-12 xs--middle"
  [class.active]="isOpen">
  <div class="container">
    <div class="xs--middle header__nav-wrapper">
      <a
        *ngIf="navigationLinks.home?.routerPath; else externalHomeNavigationLink"
        (click)="onClick($event)"
        [routerLink]="[navigationLinks.home?.routerPath]"
        class="header__logo xs--middle flex-auto"
      >
        <img
          src="{{clientLogoPath ? clientLogoPath : 'assets/images/dg-logo-colour.png'}}"
          role="img"
          alt="Domestic & General"
        />
      </a>

      <ng-template #externalHomeNavigationLink>
        <a
          (click)="onClick($event)"
          [href]="navigationLinks.home?.href"
          class="header__logo xs--middle flex-auto"
          target="_blank"
        >
          <img
            src="{{clientLogoPath ? clientLogoPath : 'assets/images/dg-logo-colour.png'}}"
            role="img"
            alt="Domestic & General"
          />
        </a>
      </ng-template>

      <nav class="header__nav flex-auto">
        <div
          class="nav__link--list xs--hidden xs--stack" [class.active]="isOpen" >
          <dgx-ui-wl-header-nav-link [data]="navigationLinks.products" *ngIf='!navigationLinks.products?.isHidden'></dgx-ui-wl-header-nav-link>
          <dgx-ui-wl-header-nav-link [data]="navigationLinks.repair" *ngIf='!navigationLinks.repair?.isHidden'></dgx-ui-wl-header-nav-link>
          <dgx-ui-wl-header-nav-link [data]="navigationLinks.faq" *ngIf='!navigationLinks.faq?.isHidden'></dgx-ui-wl-header-nav-link>

          <dgx-ui-wl-header-nav-link class="sm--hidden" [data]="navigationLinks.profile" *ngIf='!navigationLinks.profile?.isHidden'></dgx-ui-wl-header-nav-link>
          <dgx-ui-wl-header-nav-link class="sm--hidden" [data]="navigationLinks.basket" *ngIf='!navigationLinks.basket?.isHidden'></dgx-ui-wl-header-nav-link>
          <dgx-ui-wl-header-nav-link class="sm--hidden" [data]="navigationLinks.logout" *ngIf='!navigationLinks.logout?.isHidden' (clicked)="logoutClick()"></dgx-ui-wl-header-nav-link>
        </div>

        <div class="header__filler"></div>

        <dgx-ui-wl-header-powered-by
          [clientLogoPath]="clientLogoPath"
          class="mr-2"
        ></dgx-ui-wl-header-powered-by>

        <div class='header__secondary-nav'>
          <div
            class="header__account-nav"
            [ngClass]="{ 'header__account-nav--open': navOpen }"
          >
            <div class="header__secondary-nav-divider" *ngIf="clientLogoPath"></div>
            <dgx-ui-wl-header-nav-link [data]="navigationLinks.myDetails" *ngIf='!navigationLinks.myDetails?.isHidden'></dgx-ui-wl-header-nav-link>
            <dgx-ui-wl-header-nav-link [data]="navigationLinks.logout" *ngIf='!navigationLinks.logout?.isHidden' (clicked)="logoutClick()"></dgx-ui-wl-header-nav-link>
          </div>

          <div class="secondary-nav__icon--box xs--hidden sm-row sm--middle">
            <dgx-ui-wl-header-nav-link class="hide--label" [data]="navigationLinks.profile" *ngIf='!navigationLinks.profile?.isHidden' (clicked)="onToggleSubMenu()"></dgx-ui-wl-header-nav-link>
            <dgx-ui-wl-header-nav-link class="hide--label" [data]="navigationLinks.basket" *ngIf='!navigationLinks.basket?.isHidden'></dgx-ui-wl-header-nav-link>
          </div>
          <button
            id="menu-button"
            [class.active]="isOpen"
            class="header__menu-button"
            (click)="onToggleMenu()"
          ></button>
        </div>
      </nav>
    </div>
  </div>
</header>
