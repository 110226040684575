import { ApplianceFormField, ApplianceFormValue } from '@common/util-models';

export function revealApplianceDetailsFormProgressively(
  formValue: ApplianceFormValue,
  fixedValueControls: ApplianceFormField[],
  applianceIsHeating?: boolean,
  inBrandWarranty?: boolean,
  inExtendedWarranty?: boolean,
  inWarranty?: boolean
) {
  const appliance = formValue[ApplianceFormField.Appliance];
  const brand = formValue[ApplianceFormField.Brand];
  const goodWorkingOrder = formValue[ApplianceFormField.GoodWorkingOrder];
  const underGuarantee = formValue[ApplianceFormField.UnderGuarantee];
  const purchaseDate = formValue[ApplianceFormField.PurchaseDate];
  const controlsToHide: ApplianceFormField[] = [...fixedValueControls];

  if (!appliance || !brand) {
    controlsToHide.push(
      ApplianceFormField.PurchaseDate,
      ApplianceFormField.PurchasePrice,
      ApplianceFormField.GuaranteeDuration
    );
  }

  if (!goodWorkingOrder) {
    controlsToHide.push(
      ApplianceFormField.PurchaseDate,
      ApplianceFormField.PurchasePrice,
      ApplianceFormField.GuaranteeDuration
    );
  } else if (goodWorkingOrder === 'No') {
    controlsToHide.push(
      ApplianceFormField.PurchaseDate,
      ApplianceFormField.PurchasePrice,
      ApplianceFormField.Submit,
      ApplianceFormField.UnderGuarantee,
      ApplianceFormField.GuaranteeDuration
    );
  }

  if (!underGuarantee || (applianceIsHeating && underGuarantee === 'Yes')) {
    controlsToHide.push(
      ApplianceFormField.PurchaseDate,
      ApplianceFormField.PurchasePrice,
      ApplianceFormField.GuaranteeDuration
    );
  } else if (underGuarantee === 'No') {
    controlsToHide.push(ApplianceFormField.GuaranteeDuration);
  }

  if (inBrandWarranty) {
    controlsToHide.push(
      ApplianceFormField.Submit,
      ApplianceFormField.UnderGuarantee,
      ApplianceFormField.GuaranteeDuration
    );
  } else if (inExtendedWarranty) {
    controlsToHide.push(
      ApplianceFormField.Submit,
      ApplianceFormField.PurchasePrice,
      ApplianceFormField.PurchaseDate
    );
  } else if (inWarranty) {
    controlsToHide.push(ApplianceFormField.Submit);
  }

  if (!purchaseDate?.year || (!applianceIsHeating && !purchaseDate.month)) {
    controlsToHide.push(ApplianceFormField.PurchasePrice);
  }

  return controlsToHide.filter(
    (control, index) => controlsToHide.indexOf(control) === index
  );
}
