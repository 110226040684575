import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  ApplianceDetailsConfig,
  CheckoutConfig,
  DiscountStoreConfig,
  QuotesConfig,
  RemoteDataState,
  SidedoorConfig,
} from '@common/util-models';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import * as FeatureConfigSelectors from './feature-config.selectors';
import * as FeatureConfigActions from './feature-config.actions';
import { FeatureConfigState } from './feature-config.reducers';
import { filterNullUndefined } from '@common/util-foundation';

type Sidedoor$ReturnType<T, Z> = Z extends true
  ? Observable<T>
  : Observable<T | null | undefined>;

@Injectable()
export class FeatureConfigFacade {
  remoteState$ = this.store.pipe(select(FeatureConfigSelectors.getRemoteState));
  loadComplete$ = this.remoteState$.pipe(
    filter(
      ({ kind }) =>
        kind === RemoteDataState.OK || kind === RemoteDataState.Error
    )
  );
  applianceDetails$ = this.store.pipe(
    select(FeatureConfigSelectors.getApplianceDetailsConfig),
    filter((config) => !!config)
  ) as Observable<ApplianceDetailsConfig>;

  discountStore$: Observable<DiscountStoreConfig | undefined> = this.store.pipe(
    select(FeatureConfigSelectors.getDiscountStoreConfig)
  );

  checkout$ = this.store.pipe(
    select(FeatureConfigSelectors.getCheckoutConfig),
    filter((config) => !!config)
  ) as Observable<CheckoutConfig>;

  quotes$ = this.store.pipe(
    select(FeatureConfigSelectors.getQuotesConfig),
    filter((config) => !!config)
  ) as Observable<QuotesConfig>;

  load(): Promise<void> {
    const promise = new Promise<void>((resolve) => {
      this.loadComplete$.pipe(take(1)).subscribe(() => resolve());
    });
    this.store.dispatch(FeatureConfigActions.loadFeatureConfig());
    return promise;
  }

  constructor(private store: Store<FeatureConfigState>) {}

  sidedoor$<T = SidedoorConfig, Z extends boolean = boolean>(
    filterNullAndUndefinedValues: Z
  ): Sidedoor$ReturnType<T, Z> {
    return this.store.pipe(
      select(
        (FeatureConfigSelectors.getSidedoorConfig as unknown) as (
          state: FeatureConfigState
        ) => T | undefined | null
      ),
      (observable) => {
        if (filterNullAndUndefinedValues) {
          return observable.pipe(filterNullUndefined());
        }
        return observable;
      }
    ) as Sidedoor$ReturnType<T, Z>;
  }
}
