import {
  FeatureConfigPartialState,
  FeatureConfigSelectors,
} from '@common/data-access-feature-config';
import { applianceIsHeating } from '@common/util-foundation';
import {
  Basket,
  BasketItem,
  BasketItemQuoteDetails,
  Cover,
  CoverType,
  isRemoteDataError,
  isRemoteDataLoading,
  isRemoteDataUpdating,
  QuotesConfig,
  RemoteData,
  SelectedBasketItem,
} from '@common/util-models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  QuotesPartialState,
  QuotesState,
  QUOTES_FEATURE_KEY,
} from './quotes.reducer';
import { generateCoverFromBasketItemQuote } from './quotes.utils';
import {
  currentItem,
  currentSelectedItem,
  currentSelectedItemQuote,
  firstQuoteInCurrentItem,
} from './single-item.utils';

export const getQuotesState = createFeatureSelector<
  QuotesPartialState,
  QuotesState
>(QUOTES_FEATURE_KEY);

export const getQuotesRemoteState = createSelector(
  getQuotesState,
  (state: QuotesState) => state.remoteState
);

export const getQuotesRemoteStateLoading = createSelector(
  getQuotesRemoteState,
  (remoteState: RemoteData<null>): boolean => isRemoteDataLoading(remoteState)
);

export const getQuotesRemoteStateUpdating = createSelector(
  getQuotesRemoteState,
  (remoteState: RemoteData<null>): boolean => isRemoteDataUpdating(remoteState)
);

export const getQuotesRemoteStateUnstable = createSelector(
  getQuotesRemoteState,
  (remoteState: RemoteData<null>): boolean =>
    isRemoteDataUpdating(remoteState) || isRemoteDataLoading(remoteState)
);

export const getQuotesError = createSelector(
  getQuotesRemoteState,
  (remoteState: RemoteData) => {
    if (isRemoteDataError(remoteState)) {
      return remoteState.error;
    }
    return undefined;
  }
);

export const getBasket = createSelector(
  getQuotesState,
  (state: QuotesState): Basket | undefined => state.quoteApiResult?.basket
);

export const getBasketId = createSelector(
  getQuotesState,
  (state: QuotesState) => state.quoteApiResult?.basket.basketId
);

export const getAllItems = createSelector(
  getQuotesState,
  (state: QuotesState) => state.quoteApiResult?.basket.items ?? []
);

// Single item basket selector
export const getCurrentItem = createSelector(
  getQuotesState,
  (state: QuotesState): BasketItem | undefined => currentItem(state)
);

export const getCurrentItemIsHeatingAppliance = createSelector(
  getQuotesState,
  (state: QuotesState): boolean =>
    applianceIsHeating(currentItem(state)?.data?.applianceDetails)
);

export const getCurrentItemId = createSelector(
  getCurrentItem,
  (currentItem: BasketItem | undefined): string | undefined =>
    currentItem?.itemId
);

// Single item basket selector
export const getCurrentItemFirstQuoteAsSelectedBasketItem = createSelector(
  getCurrentItem,
  (currentItem: BasketItem | undefined): SelectedBasketItem | undefined =>
    currentItem
      ? {
          itemId: currentItem.itemId,
          quoteId: currentItem.data.quotes[0].quoteId,
        }
      : undefined
);

// Single item basket selector
export const getFirstCoverType = createSelector(
  getQuotesState,
  (state: QuotesState) =>
    firstQuoteInCurrentItem(state)?.coverType || CoverType.Standard
);

// Single item basket selector
export const getSelectedCoverType = createSelector(
  getQuotesState,
  (state: QuotesState): CoverType =>
    currentItem(state)?.data.quotes.find(
      (quote) => quote.quoteId === currentSelectedItem(state)?.quoteId
    )?.coverType || CoverType.Standard
);

// Based on single item basket selector
export const getSelectedCoverName = createSelector(
  FeatureConfigSelectors.getQuotesConfig,
  getSelectedCoverType,
  (config: QuotesConfig | undefined, selectedCoverType: CoverType): string => {
    const name =
      config?.coverTypeNames && config?.coverTypeNames[selectedCoverType];
    return name ? name : '';
  }
);

export const getInitialCoverType = createSelector(
  getQuotesState,
  (state: QuotesState): CoverType => state.initialCoverType
);

// Single item basket selector
export const getSelectedExcess = createSelector(
  getQuotesState,
  (state: QuotesState): number =>
    currentItem(state)?.data.quotes.find(
      (quote) => quote.quoteId === currentSelectedItem(state)?.quoteId
    )?.excessAmount as number
);

// Based on single item basket selector
export const getApplianceDetails = createSelector(
  getCurrentItem,
  (quote: BasketItem | undefined) => quote?.data?.applianceDetails
);

// Based on single item basket selector
export const getQuoteDetails = createSelector(
  getCurrentItem,
  (quote: BasketItem | undefined) => quote?.data?.quotes
);

/**
 * Gets the "BasketItemQuoteDetails" entry for the FIRST Item, selected quote
 * Returns Payment methods as object rather than array
 * Single item basket selectors
 */
export const getSelectedCover = createSelector(
  getQuotesState,
  getSelectedCoverType,
  FeatureConfigSelectors.getQuotesConfig,
  (
    state: QuotesState,
    selectedCoverType: CoverType,
    config?: QuotesConfig
  ): Cover | undefined => {
    return generateCoverFromBasketItemQuote(
      selectedCoverType,
      currentSelectedItemQuote(state),
      config
    );
  }
);

/**
 * Gets the "BasketItemQuoteDetails" entry for the FIRST Item, current quote
 * Returns Payment methods as object rather than array
 */
export const getCurrentItemFirstCover = createSelector(
  getQuotesState,
  getFirstCoverType,
  FeatureConfigSelectors.getQuotesConfig,
  (
    state: QuotesState,
    selectedCoverType: CoverType,
    config?: QuotesConfig
  ): Cover | undefined => {
    return generateCoverFromBasketItemQuote(
      selectedCoverType,
      firstQuoteInCurrentItem(state),
      config
    );
  }
);

// Based on single item basket selectors
export const getPaymentOptions = createSelector(getSelectedCover, (cover) => {
  const paymentOptions = cover?.paymentOptions || undefined;

  if (paymentOptions?.directDebit?.fee && paymentOptions?.card?.fee) {
    return {
      ...paymentOptions,
      directDebit: {
        ...paymentOptions.directDebit,
        directDebitDiscount:
          paymentOptions.card.fee - paymentOptions.directDebit.fee,
      },
    };
  }
  return paymentOptions;
});

// Based on single item basket selectors
export const getPeriodOfCover = createSelector(getSelectedCover, (cover) => {
  return cover?.periodOfCover ?? undefined;
});

// Based on single item basket selectors
export const getReturnedCoverTypes = createSelector(
  getQuoteDetails,
  (quotes): Array<CoverType> => {
    //find all the coverTypes returned by the Quote API
    const coverTypes: CoverType[] =
      quotes?.map(({ coverType }: BasketItemQuoteDetails) => coverType) || [];
    //filter to unique values
    return coverTypes.filter(
      (coverType, index) => coverTypes.indexOf(coverType) === index
    );
  }
);

// Based on single item basket selectors
export const getFirstCoverTypeFromWhitelist = createSelector<
  QuotesPartialState & FeatureConfigPartialState,
  QuotesConfig | undefined,
  CoverType[],
  CoverType | undefined
>(
  FeatureConfigSelectors.getQuotesConfig,
  getReturnedCoverTypes,
  (config: QuotesConfig | undefined, returnedCoverTypes) => {
    return (config?.coverTypesWhitelist || []).find((coverType) =>
      returnedCoverTypes.includes(coverType)
    );
  }
);

export const getCoverTypeNames = createSelector<
  QuotesPartialState & FeatureConfigPartialState,
  QuotesConfig | undefined,
  QuotesConfig['coverTypeNames']
>(
  FeatureConfigSelectors.getQuotesConfig,
  (config: QuotesConfig | undefined) => config?.coverTypeNames || {}
);

export const getRouteParamsAutoSelect = createSelector(
  getQuotesState,
  (state: QuotesState): { coverType: CoverType; excess: number } | undefined =>
    state.routeParamsAutoSelect
);

// Multi item basket selector
export const getSelectedItems = createSelector(
  getQuotesState,
  (state: QuotesState): SelectedBasketItem[] => state.selectedItems
);

export const getUnusedItemIds = createSelector(
  getQuotesState,
  (state: QuotesState): string[] => {
    if (!state.quoteApiResult?.basket) {
      return [];
    }
    const selectedItemIds: string[] = state.selectedItems.map(
      (selectedItem: SelectedBasketItem) => selectedItem.itemId
    );
    const currentItemId: string | undefined = state.currentItemId;

    return state.quoteApiResult.basket.items
      .map((item: BasketItem) => item.itemId)
      .filter(
        (itemId: string) =>
          !selectedItemIds.includes(itemId) && currentItemId !== itemId
      );
  }
);

export const getMostRecentActionMessage = createSelector(
  getQuotesState,
  (state: QuotesState): string | undefined => state.mostRecentActionMessage
);
