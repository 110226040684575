<div class="modal__overlay" [class.open]="open" (click)="handleOverlayClick($event)">
  <div class="container">
    <div class="modal__content {{modalCls}}" aria-modal="true" [attr.aria-hidden]="open?undefined:'true'" #modal
         tabindex="-1">
      <div class="modal__header">
        <button class="modal__hide-button icon icon-close" (click)="hide()" aria-label="Close"></button>
      </div>
      <div class="modal__body">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>

