import { createAction, props } from '@ngrx/store';
import {
  CreateQuoteResponse,
  GetOfferRenewalQuoteRequest,
  OfferRenewalFormSubmit,
} from '@common/util-models';
import { HttpErrorResponse } from '@angular/common/http';

export const getOfferQuote = createAction(
  '[OfferComponent] Get Offer Quote',
  props<{
    offerQuoteRequest: GetOfferRenewalQuoteRequest;
    formData: OfferRenewalFormSubmit;
  }>()
);

export const getEmailOfferQuote = createAction(
  '[OfferQueryParamsResolver] Get offer quote through email link',
  props<{
    offerQuoteRequest: GetOfferRenewalQuoteRequest;
    formData: OfferRenewalFormSubmit;
  }>()
);

export const getRedirectOfferQuote = createAction(
  '[OfferRenewalQueryParamsResolver] Get offer quote via a homepage redirect',
  props<{
    offerQuoteRequest: GetOfferRenewalQuoteRequest;
    formData: OfferRenewalFormSubmit;
    referrer: string;
  }>()
);

export const getOfferQuoteSuccess = createAction(
  '[OfferRenewalEffects] Get Offer Quote Success',
  props<{
    quoteResponse: CreateQuoteResponse;
    correlationId: string | null;
  }>()
);

export const getOfferQuoteFailure = createAction(
  '[OfferRenewalEffects] Get Offer Quote Failure',
  props<{
    error: HttpErrorResponse;
  }>()
);

export const getRedirectOfferQuoteFailure = createAction(
  '[OfferRenewalEffects] Get Offer Quote Failure via homepage redirect',
  props<{
    error: HttpErrorResponse;
  }>()
);

export const getPlanRenewalQuote = createAction(
  '[RenewalComponent] Get Plan Renewal Quote',
  props<{
    planRenewalQuoteRequest: GetOfferRenewalQuoteRequest;
    formData: OfferRenewalFormSubmit;
  }>()
);

export const getEmailPlanRenewalQuote = createAction(
  '[RenewalQueryParamsResolver] Get plan renewal quote through an email link',
  props<{
    planRenewalQuoteRequest: GetOfferRenewalQuoteRequest;
    formData: OfferRenewalFormSubmit;
  }>()
);

export const getRedirectPlanRenewalQuote = createAction(
  '[OfferRenewalQueryParamsResolver] Get plan renewal quote via homepage redirect',
  props<{
    planRenewalQuoteRequest: GetOfferRenewalQuoteRequest;
    formData: OfferRenewalFormSubmit;
    referrer: string;
  }>()
);

export const getPlanRenewalQuoteSuccess = createAction(
  '[OfferRenewalEffects] Get Plan Renewal Quote Success',
  props<{
    quoteResponse: CreateQuoteResponse;
    correlationId: string | null;
  }>()
);

export const getPlanRenewalQuoteFailure = createAction(
  '[OfferRenewalEffects] Get Plan Renewal Quote Failure',
  props<{
    error: HttpErrorResponse;
  }>()
);

export const getRedirectPlanRenewalQuoteFailure = createAction(
  '[OfferRenewalEffects] Get Plan Renewal Quote Failure via homepage redirect',
  props<{
    error: HttpErrorResponse;
  }>()
);

export const multiQuoteError = createAction(
  '[OfferRenewalEffects] multi quotes for offer or renewal found'
);
