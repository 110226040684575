import { AbstractControl, FormGroup } from '@angular/forms';
import { MonoTypeOperatorFunction, pipe } from 'rxjs';
import { tap } from 'rxjs/operators';

export function resetControls(
  ...controlKeys: string[]
): MonoTypeOperatorFunction<FormGroup | null | undefined> {
  return pipe(
    tap((form: FormGroup | null | undefined) => {
      form?.patchValue(
        controlKeys.reduce(
          (patchValue: { [key: string]: undefined }, controlKey: string) => ({
            ...patchValue,
            [controlKey]: undefined,
          }),
          {}
        )
      );

      controlKeys.forEach((controlKey: string) => {
        const control: AbstractControl | null | undefined = form?.get(
          controlKey
        );
        control?.markAsPristine();
        control?.markAsUntouched();
        control?.setErrors(null);
        control?.updateValueAndValidity();
      });
    })
  );
}
