import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

export function getAllControls(
  control?: AbstractControl | null
): AbstractControl[] {
  if (control instanceof FormGroup) {
    return Object.values(control.controls).reduce(
      (
        descendantControls: AbstractControl[],
        descendantControl: AbstractControl
      ) => {
        return descendantControls.concat(getAllControls(descendantControl));
      },
      [control]
    );
  } else if (control instanceof FormControl) {
    return [control];
  }
  return [];
}

export function getAllDisabledControls(
  control?: AbstractControl | null
): AbstractControl[] {
  return getAllControls(control).filter(
    (descendantControl: AbstractControl) => descendantControl.disabled
  );
}

export function enableControls(
  ...controls: (AbstractControl | null | undefined)[]
): void {
  controls.forEach((control) => {
    if (control instanceof FormGroup) {
      Object.values(
        control.controls
      ).forEach((descendantControl: AbstractControl) =>
        enableControls(descendantControl)
      );
    }
    control?.enable();
  });
}

export function disableControls(
  ...controls: (AbstractControl | null | undefined)[]
): void {
  controls.forEach((control) => {
    if (control instanceof FormGroup) {
      Object.values(
        control.controls
      ).forEach((descendantControl: AbstractControl) =>
        disableControls(descendantControl)
      );
    }
    control?.disable();
  });
}
