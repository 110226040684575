<ng-container *ngIf="data?.length">
  <div *ngFor="let item of data" class="display-info-section">
    <div class="display-info-section__body">
      <div
        class="label display-info-section__title"
        data-test-hook="display-info-product"
      >
        {{ item.title }}
      </div>
      <dl>
        <div
          class="value display-info-section__details"
          *ngFor="let detail of item.details"
        >
          <dt class="label">{{ detail?.key }}: </dt>
          <dd *ngIf="detail?.value" class="value">{{ detail.value }}</dd>
        </div>
      </dl>
    </div>
    <div class="display-info-section__aside">
      <p class="label" data-test-hook="display-info-value">
        {{ item?.aside?.key }}
      </p>
      <p *ngIf="item?.aside?.value" class="value">{{ item.aside.value }}</p>
    </div>
  </div>
  <div *ngIf="footer" class="display-info-section__footer">
    <div>
      <p class="label">{{ footer?.key }}</p>
      <p *ngIf="footer?.value" class="label">{{ footer.value }}</p>
    </div>
    <p *ngIf="footer?.subtitle" class="value">{{ footer.subtitle }}</p>
  </div>
</ng-container>
