import { GaTags } from '@common/util-models';
import { MonoTypeOperatorFunction, pipe } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GaTaggingService } from '../services';

export function pushToGa(
  gaTaggingService: GaTaggingService
): MonoTypeOperatorFunction<GaTags | GaTags[]> {
  return pipe(
    tap((gaTags: GaTags | GaTags[]) =>
      Array.isArray(gaTags)
        ? gaTaggingService.tag(...gaTags)
        : gaTaggingService.tag(gaTags)
    )
  );
}
