import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  Cover,
  CoverPaymentOptions,
  Quote,
  QuoteType,
  YourDetails,
} from '@common/util-models';
import { Observable } from 'rxjs';
import {
  getOfferRenewal,
  getOfferRenewalCustomerFirstName,
  getOfferRenewalMergedCustomerDetails,
  getOfferRenewalHasCardPaymentOption,
  getOfferRenewalHasDirectDebitPaymentOption,
  getOfferRenewalHasMultiplePaymentOptions,
  getOfferRenewalIsInsurance,
  getOfferRenewalPaymentOptions,
  getOfferRenewalReference,
  offerRenewalIsEmailAvailable,
  getOfferRenewalQuote,
  getOfferRenewalisHeatingAppliance,
  getGaQuoteType,
} from '../+state/offer-renewal.selectors';
import { QuotesPartialState } from '@common/data-access-quotes';
import { filterNullUndefined } from '@common/util-foundation';
import { PersonalDetailsPartialState } from '@common/data-access-personal-details';
import { OfferRenewalPartialState } from '../+state/offer-renewal.reducer';
import { SelectPaymentPartialState } from '@common/data-access-select-payment';
import { getOfferRenewalFormData } from '../+state/offer-renewal-landing-page.selectors';
import { map } from 'rxjs/operators';

/**
 * This will only contain selectors that we need to reuse across pages. This will ensure that we do not duplicate selectors across
 * pages going forward in each and every facade. There will never be methods here that dispatch actions to the store. These
 * will be injected across various feature pages/ component services/ state services. So having methods here that dispatch actions
 * will lead to action reuse and use of actions as commands. We want to avoid actions reuse and have unique actions for every specific
 * event that happens across pages.
 *
 * @export
 * @class OffersRenewalsCommonSelectorsFacade
 */
@Injectable()
export class OffersRenewalsCommonSelectorsFacade {
  offerRenewal$: Observable<Cover | undefined> = this.store.select(
    getOfferRenewal
  );

  reference$: Observable<string | undefined> = this.store.select(
    getOfferRenewalReference
  );

  postCode$: Observable<string | undefined> = this.store
    .select(getOfferRenewalFormData)
    .pipe(map((data) => data?.postCode));

  offerRenewalPaymentOptions$: Observable<CoverPaymentOptions> = this.store
    .select(getOfferRenewalPaymentOptions)
    .pipe(filterNullUndefined());

  offerRenewalHasMultiplePaymentOptions$: Observable<boolean> = this.store.select(
    getOfferRenewalHasMultiplePaymentOptions
  );

  offerRenewalHasCardPaymentOption$: Observable<boolean> = this.store.select(
    getOfferRenewalHasCardPaymentOption
  );

  offerRenewalHasDirectDebitPaymentOption$: Observable<boolean> = this.store.select(
    getOfferRenewalHasDirectDebitPaymentOption
  );

  offerRenewalMergedCustomerDetails$: Observable<YourDetails> = this.store
    .select(getOfferRenewalMergedCustomerDetails)
    .pipe(filterNullUndefined());

  offerRenewalIsInsurance$: Observable<boolean> = this.store.select(
    getOfferRenewalIsInsurance
  );

  offerRenewalCustomerFirstName$: Observable<
    string | undefined
  > = this.store.select(getOfferRenewalCustomerFirstName);

  offerRenewalIsEmailAvailable$: Observable<boolean> = this.store.select(
    offerRenewalIsEmailAvailable
  );

  offerRenewalQuote$: Observable<Quote> = this.store
    .select(getOfferRenewalQuote)
    .pipe(filterNullUndefined());

  offerRenewalIsHeatingAppliance$: Observable<boolean> = this.store
    .select(getOfferRenewalisHeatingAppliance)
    .pipe(filterNullUndefined());

  gaQuoteType$: Observable<QuoteType> = this.store.pipe(
    select(getGaQuoteType),
    filterNullUndefined()
  );

  constructor(
    private store: Store<
      QuotesPartialState &
        PersonalDetailsPartialState &
        SelectPaymentPartialState &
        OfferRenewalPartialState
    >
  ) {}
}
