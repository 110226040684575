import { Inject, Injectable, OnDestroy, Optional } from '@angular/core';
import { Dictionary, GaTags, GoogleTagManager } from '@common/util-models';
import { combineLatest, isObservable, Observable, of, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import {
  GOOGLE_TAG_GLOBAL_PROPERTIES,
  GOOGLE_TAG_MANAGER,
} from '../tokens/gtm.token';

@Injectable({
  providedIn: 'root',
})
export class GaTaggingService implements OnDestroy {
  private gaTagsSubject = new Subject<GaTags>();
  private gaTags$ = this.gaTagsSubject.asObservable();

  private readonly destroySubject = new Subject<void>();
  readonly destroy$ = this.destroySubject.asObservable();

  constructor(
    @Inject(GOOGLE_TAG_MANAGER) private gtm: GoogleTagManager,
    @Optional()
    @Inject(GOOGLE_TAG_GLOBAL_PROPERTIES)
    globalPropsObservableOrValue:
      | Dictionary<string>
      | Observable<Dictionary<string>>
      | null
  ) {
    this.pushToGtm(globalPropsObservableOrValue);
  }

  tag(...tags: GaTags[]) {
    tags.forEach((tag) => {
      this.gaTagsSubject.next(tag);
    });
  }

  private pushToGtm(
    globalPropsObservableOrValue:
      | Dictionary<string>
      | Observable<Dictionary<string>>
      | null
  ) {
    combineLatest([
      this.gaTags$,
      this.normalizeGlobalProps(globalPropsObservableOrValue),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([tag, globalProps]) => {
          const combined = globalProps ? { ...globalProps, ...tag } : tag;
          console.log('data:::', combined);
          this.gtm.push(combined);
        },
      });
  }

  private normalizeGlobalProps(
    globalPropsObservableOrValue:
      | Dictionary<string>
      | Observable<Dictionary<string>>
      | null
  ) {
    return isObservable(globalPropsObservableOrValue)
      ? globalPropsObservableOrValue.pipe(take(1))
      : of(globalPropsObservableOrValue);
  }

  ngOnDestroy() {
    this.destroySubject.next();
  }
}
