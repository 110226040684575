import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CheckoutPersonalDetailsFormValue } from '@oren/util-foundation';
import { OREN_CHECKOUT_FEATURE_KEY, CheckoutState } from './checkout.reducer';

export const getCheckoutState = createFeatureSelector<CheckoutState>(
  OREN_CHECKOUT_FEATURE_KEY
);

export const getPaperlessDocumentPreference = createSelector(
  getCheckoutState,
  (state: CheckoutState): boolean => state.paperlessDocumentPreference
);

export const getTermsAndConditionsAccepted = createSelector(
  getCheckoutState,
  (state: CheckoutState): boolean => state.termsAndConditionsAccepted
);

export const getEmailAddress = createSelector(
  getCheckoutState,
  (state: CheckoutState): string => state.email
);

export const getCheckoutPersonalDetailsFormValue = createSelector(
  getCheckoutState,
  (state: CheckoutState): CheckoutPersonalDetailsFormValue => ({
    email: state.email,
    paperlessDocumentPreference: state.paperlessDocumentPreference,
    termsAndConditionsAccepted: state.termsAndConditionsAccepted,
  })
);
