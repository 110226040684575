import { Injectable, OnDestroy } from '@angular/core';
import {
  filterNullUndefined,
  QuoteParamsParserService,
} from '@common/util-foundation';
import {
  Appliance,
  ApplianceDetailsQueryParamsForm,
  ApplianceFormField,
  ApplianceFormSubmit,
  ApplianceFormValue,
  ApplianceFormValueWithItemId,
  Brand,
  CreateQuoteRequest,
  ParseResult,
  RemoteData,
} from '@common/util-models';
import { select, Store } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, mapTo, tap, withLatestFrom } from 'rxjs/operators';
import * as ApplianceDetailsActions from './appliance-details.actions';
import { ApplianceDetailsState } from './appliance-details.reducer';
import * as ApplianceFormDataActions from './appliance-form-data/appliance-form-data.actions';
import { ApplianceFormDataState } from './appliance-form-data/appliance-form-data.reducer';
import * as ApplianceFormDataSelectors from './appliance-form-data/appliance-form-data.selectors';
import * as AppliancesActions from './appliances/appliances.actions';
import * as AppliancesSelectors from './appliances/appliances.selectors';
import * as BrandsSelectors from './brands/brands.selectors';

@Injectable()
export class ApplianceDetailsFacade implements OnDestroy {
  appliances$: Observable<Appliance[]> = this.store.pipe(
    select(AppliancesSelectors.getAppliances)
  );

  selectedAppliance$: Observable<Appliance | undefined> = this.store.pipe(
    select(AppliancesSelectors.getSelectedAppliance)
  );

  formValues$: Observable<ApplianceFormDataState> = this.store.select(
    ApplianceFormDataSelectors.getFormDataState
  );

  brands$: Observable<Brand[]> = this.store.pipe(
    select(BrandsSelectors.getBrands)
  );

  formData$: Observable<ApplianceFormValue | undefined> = this.store.pipe(
    select(ApplianceFormDataSelectors.getPrepopulatedFormValue)
  );

  appliancesLoaded$: Observable<RemoteData> = this.store.pipe(
    select(AppliancesSelectors.getAppliancesLoaded)
  );

  brandsLoaded$: Observable<RemoteData> = this.store.pipe(
    select(BrandsSelectors.getBrandsLoaded)
  );

  validate$: Observable<boolean> = this.quoteParamsParserService.parsedResult$.pipe(
    map((parsedResult) => parsedResult?.result === ParseResult.Failure)
  );

  private createQuoteSubject = new Subject<ApplianceFormSubmit>();
  private createQuote$ = this.createQuoteSubject.asObservable().pipe(
    withLatestFrom(this.selectedAppliance$),
    tap(([formValues, selectedAppliance]) =>
      this.store.dispatch(
        ApplianceDetailsActions.createQuoteRequest({
          quoteRequest: this.getQuoteRequest(formValues, selectedAppliance),
          formData: formValues,
        })
      )
    ),
    mapTo(null)
  );
  private subscription = new Subscription();

  constructor(
    private store: Store<ApplianceDetailsState>,
    private quoteParamsParserService: QuoteParamsParserService<ApplianceDetailsQueryParamsForm>
  ) {
    this.subscription.add(this.createQuote$.subscribe());
  }

  selectAppliance(itemCode: string) {
    this.store.dispatch(
      AppliancesActions.applianceSelected({ code: itemCode })
    );
  }

  selectFormById(itemId: string): Observable<ApplianceFormValueWithItemId> {
    return this.store
      .select(ApplianceFormDataSelectors.selectFormById({ itemId }))
      .pipe(filterNullUndefined());
  }

  selectBrand(code: string): Observable<Brand> {
    return this.store
      .select(BrandsSelectors.getBrandByCode({ code }))
      .pipe(filterNullUndefined());
  }

  createQuote(formValues: ApplianceFormSubmit) {
    this.createQuoteSubject.next(formValues);
  }

  fillFormWithQueryParams(formData: ApplianceFormSubmit) {
    this.store.dispatch(
      ApplianceFormDataActions.setPrepopulatedFormValue({ formData })
    );
  }

  clearPrepopulatedFormValue(): void {
    this.store.dispatch(ApplianceFormDataActions.clearPrepopulatedFormValue());
  }

  private getQuoteRequest(
    formValues: ApplianceFormSubmit,
    selectedAppliance: Appliance | undefined
  ) {
    return {
      itemType: 'QUOTE',
      applianceCode: formValues.applianceCode,
      brandCode: formValues.brandCode,
      purchaseMonth: formValues[ApplianceFormField.PurchaseDate].month,
      purchaseYear: formValues[ApplianceFormField.PurchaseDate].year,
      purchasePrice: Number(formValues[ApplianceFormField.PurchasePrice]),
      applianceCategory:
        selectedAppliance?.category ?? formValues?.applianceCategory,
    } as CreateQuoteRequest;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
