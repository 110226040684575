import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'wl-checkout-banner',
  templateUrl: './checkout-banner.component.html',
  styleUrls: ['./checkout-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutBannerComponent {
  @Input() title?: string;
  @Input() multiItemQuotesCount?: number;
  @Output() handleClick: EventEmitter<void> = new EventEmitter();

  linkText = 'Go to quote summary';
  subtitle = 'Or continue adding appliances below';

  onClick(): void {
    this.handleClick.emit();
  }
}
