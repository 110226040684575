import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import type { detailData } from '@domgen/dgx-fe-base-components';
import { YourDetails } from '@common/util-models';
@Component({
  selector: 'oren-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss'],
})
export class OrenCustomerDetailsComponent implements OnChanges {
  @Input() personalDetails: YourDetails | undefined | null;

  detail: detailData[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.personalDetails) {
      return;
    }
    if (this.personalDetails) {
      this.mapToDetailArray(this.personalDetails);
    }
  }

  mapToDetailArray(personalDetail: YourDetails) {
    this.detail = [
      {
        key: '',
        value: personalDetail.name.trim(),
      },
    ];

    if (personalDetail.email?.trim() !== '') {
      this.detail.push({
        key: '',
        value: personalDetail.email?.trim(),
      });
    }

    if (personalDetail.mobile?.trim() !== '') {
      this.detail.push({
        key: '',
        value: personalDetail.mobile?.trim(),
      });
    }

    this.detail.push({
      key: '',
      value: this.addressGenerator(personalDetail),
    });
  }

  addressGenerator(personalDetail: YourDetails) {
    let addressData = '';
    if (personalDetail.address) {
      const adressArray = Object.values(personalDetail.address);
      addressData = adressArray.filter((v) => !!v).join('</br>');
    }
    return addressData;
  }
}
