import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BuildConfigService,
  filterNullUndefined,
  normalized,
} from '@common/util-foundation';
import {
  ApplianceResponse,
  Basket,
  CreateQuoteRequest,
  CreateQuoteResponse,
  GetOfferRenewalQuoteRequest,
  ItemType,
  Normalize,
} from '@common/util-models';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { delayWhen, first, map } from 'rxjs/operators';
import {
  addApplianceCategoryToBasket,
  addNumberSubsequentPaymentsToBasket,
} from '../utils/quotes-mapper/quotes-mapper.utils';

@Injectable()
export class QuotesApiService {
  private appliances$ = new BehaviorSubject<
    Normalize<ApplianceResponse> | undefined
  >(undefined);

  constructor(
    private httpClient: HttpClient,
    private buildConfigService: BuildConfigService
  ) {
    this.loadApplianceResponses();
  }

  createQuote(
    createQuoteParams: CreateQuoteRequest | GetOfferRenewalQuoteRequest
  ) {
    const config = this.buildConfigService.config;
    const createQuoteUrl = `${config.basketApi}/quote`;
    return this.httpClient
      .post<{ result: CreateQuoteResponse }>(
        createQuoteUrl,
        createQuoteParams,
        {
          headers: this.headers,
        }
      )
      .pipe(
        delayWhen(() => this.appliancesLoading$),
        map((response: { result: CreateQuoteResponse }) => response.result),
        map((quoteResponse: CreateQuoteResponse) => ({
          ...quoteResponse,
          basket: addNumberSubsequentPaymentsToBasket(quoteResponse.basket),
        })),
        map((quoteResponse: CreateQuoteResponse) => ({
          ...quoteResponse,
          basket: addApplianceCategoryToBasket(
            quoteResponse.basket,
            this.appliances
          ),
        }))
      );
  }

  removeItemsFromQuote(
    basketId: string | undefined,
    ...itemIds: string[]
  ): Observable<Basket> {
    if (!basketId) {
      return throwError('No basket id provided');
    }
    return this.httpClient
      .post<{ result: Basket }>(
        `${this.buildConfigService.config.basketApi}/remove-items`,
        {
          basketId,
          itemIds,
          itemType: ItemType.Quote,
        },
        {
          headers: this.headers,
        }
      )
      .pipe(
        delayWhen(() => this.appliancesLoading$),
        map((response: { result: Basket }) => response.result),
        map((basket: Basket) => addNumberSubsequentPaymentsToBasket(basket)),
        map((basket: Basket) =>
          addApplianceCategoryToBasket(basket, this.appliances)
        )
      );
  }

  private loadApplianceResponses(): void {
    this.httpClient
      .get<{ result: ApplianceResponse[] }>(
        `${this.buildConfigService.config.catalogueApi}/appliances`,
        {
          headers: this.headers,
        }
      )
      .pipe(
        first(),
        map((response: { result: ApplianceResponse[] }) => response.result),
        normalized('applianceCode')
      )
      .subscribe((appliances: Normalize<ApplianceResponse>) => {
        this.appliances$.next(appliances);
      });
  }

  private get appliances(): Normalize<ApplianceResponse> | undefined {
    return this.appliances$.value;
  }

  private get appliancesLoading$(): Observable<Normalize<ApplianceResponse>> {
    return this.appliances$.pipe(filterNullUndefined(), first());
  }

  private get headers(): { [key: string]: string } {
    return {
      'request-source': this.buildConfigService.config.catalogueStore,
      'request-action': this.buildConfigService.config.action,
    };
  }
}
