import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  applianceIsHeating,
  distinctUntilChangedDeep,
  filterNullUndefined,
  mapToLatestFrom,
} from '@common/util-foundation';
import {
  Appliance,
  ApplianceDetailsConfig,
  ApplianceFormField,
  ApplianceFormFields,
  ApplianceFormSubmit,
  ApplianceFormValue,
  Brand,
  Normalize,
} from '@common/util-models';
import {
  controlHasError,
  controlValidityChanges,
  DynamicFormbuilderService,
  enableControl,
  FieldDef,
  formValidationMessages,
  formValue,
  MonthYearUpdater,
  OptionItem,
  pluckControl,
  resetControls,
} from '@domgen/dgx-fe-dynamic-form-builder';
import { ComponentStore } from '@ngrx/component-store';
import { combineLatest, Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  pluck,
  skip,
  startWith,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { ApplianceDetailsFormConfigService } from './appliance-details-form-config.service';
import { revealApplianceDetailsFormProgressively } from './appliance-details-form.utils';

export interface ApplianceFormDataState {
  appliances: Appliance[];
  brands: Brand[];
  cmsFormData: ApplianceFormFields;
  config?: ApplianceDetailsConfig;
  form?: FormGroup;
  formBuilderConfig?: FieldDef[];
  formControlsToHide: ApplianceFormField[];
  formValue: ApplianceFormValue;
  loadingBrandSelection: boolean;
  monthYearDateUpdater?: MonthYearUpdater;
  prefilledForm?: ApplianceFormValue;
  submittedForm?: ApplianceFormValue;
}

export interface ViewModel {
  appliance?: Appliance;
  applianceNotWorking: boolean;
  form: FormGroup;
  formBuilderConfig: FieldDef[];
  formControlsToHide: ApplianceFormField[];
  inBrandWarranty?: boolean;
  inExtendedWarranty?: boolean;
  inWarranty?: boolean;
  monthYearDateUpdater?: MonthYearUpdater;
}

export const DEFAULT_PURCHASE_PRICE = 10;

@Injectable()
export class ApplianceDetailsFormStateService extends ComponentStore<ApplianceFormDataState> {
  /**
   * Selectors
   */
  readonly appliances$: Observable<Appliance[]> = this.select(
    (state: ApplianceFormDataState) => state.appliances
  );

  readonly appliancesOptions$: Observable<OptionItem[]> = this.select(
    this.appliances$,
    (appliances: Appliance[]) =>
      appliances.map((appliance) => ({
        label: appliance.description,
        value: appliance.code,
      }))
  );

  readonly brands$: Observable<Brand[]> = this.select(
    (state: ApplianceFormDataState) => state.brands
  );

  readonly brandsOptions$: Observable<OptionItem[]> = this.select(
    this.brands$,
    (brands: Brand[]) =>
      brands.map((brand) => ({ label: brand.description, value: brand.code }))
  );

  readonly cmsFormData$: Observable<ApplianceFormFields> = this.select(
    (state: ApplianceFormDataState) => state.cmsFormData
  );

  readonly config$: Observable<
    ApplianceDetailsConfig | undefined
  > = this.select((state: ApplianceFormDataState) => state.config);

  readonly form$: Observable<FormGroup> = this.select(
    (state: ApplianceFormDataState) => state.form
  ).pipe(filterNullUndefined());

  readonly formBuilderConfig$: Observable<FieldDef[]> = this.select(
    (state: ApplianceFormDataState) => state.formBuilderConfig
  ).pipe(filterNullUndefined());

  readonly formControlsToHide$: Observable<ApplianceFormField[]> = this.select(
    (state: ApplianceFormDataState) => state.formControlsToHide
  );

  readonly loadingBrandSelection$: Observable<boolean> = this.select(
    (state: ApplianceFormDataState) => state.loadingBrandSelection
  );

  readonly monthYearDateUpdater$: Observable<
    MonthYearUpdater | undefined
  > = this.select(
    (state: ApplianceFormDataState) => state.monthYearDateUpdater
  );

  readonly prefilledForm$: Observable<
    ApplianceFormValue | undefined
  > = this.select((state: ApplianceFormDataState) => state.prefilledForm);

  readonly purchasePriceOptions$: Observable<OptionItem[]> = this.select(
    this.appliances$,
    (appliances: Appliance[]) =>
      appliances[0].priceBand.map((price: OptionItem) => ({
        ...price,
        value: price.value.toString(),
      }))
  );

  readonly submittedForm$: Observable<ApplianceFormValue> = this.select(
    (state: ApplianceFormDataState) => state.submittedForm
  ).pipe(filterNullUndefined());

  /**
   * Form Selectors
   */
  readonly formValidationMessages$: Observable<
    Normalize<Normalize<string>>
  > = this.form$.pipe(
    withLatestFrom(this.formBuilderConfig$),
    formValidationMessages()
  );

  readonly formValidityChanges$: Observable<boolean> = this.form$.pipe(
    controlValidityChanges()
  );

  readonly formValue$: Observable<ApplianceFormValue> = this.form$.pipe(
    formValue()
  );

  /**
   * Form Control Selectors
   */
  readonly applianceCode$: Observable<
    string | undefined
  > = this.formValue$.pipe(
    pluck(ApplianceFormField.Appliance),
    distinctUntilChanged()
  );

  readonly appliance$: Observable<Appliance | undefined> = combineLatest([
    this.applianceCode$,
    this.appliances$,
  ]).pipe(
    map(([applianceCode, appliances]: [string | undefined, Appliance[]]) =>
      appliances?.find(
        (appliance: Appliance) => appliance.code === applianceCode
      )
    )
  );

  readonly applianceIsHeating$: Observable<boolean> = this.appliance$.pipe(
    map((appliance: Appliance | undefined) => applianceIsHeating(appliance)),
    distinctUntilChanged()
  );

  readonly applianceNotWorking$: Observable<boolean> = this.formValue$.pipe(
    pluck(ApplianceFormField.GoodWorkingOrder),
    map((inWorkingOrder: string) => inWorkingOrder === 'No'),
    distinctUntilChanged()
  );

  readonly brandCode$: Observable<string | undefined> = this.formValue$.pipe(
    pluck(ApplianceFormField.Brand),
    distinctUntilChanged()
  );

  readonly brand$: Observable<Brand | undefined> = combineLatest([
    this.brandCode$,
    this.brands$,
  ]).pipe(
    map(([brandCode, brands]: [string | undefined, Brand[]]) =>
      brands?.find((brand: Brand) => brand.code === brandCode)
    )
  );

  readonly purchaseDate$: Observable<{
    month: number;
    year: number;
  }> = this.formValue$.pipe(
    pluck(ApplianceFormField.PurchaseDate),
    distinctUntilChanged()
  );

  readonly purchasePrice$: Observable<string> = this.formValue$.pipe(
    pluck(ApplianceFormField.PurchasePrice),
    distinctUntilChanged()
  );

  readonly underGuarantee$: Observable<boolean> = this.formValue$.pipe(
    pluck(ApplianceFormField.UnderGuarantee),
    map((underGuarantee: string) => underGuarantee === 'Yes'),
    distinctUntilChanged()
  );

  /**
   * Form Submission Selectors
   */
  readonly validatedForm$: Observable<ApplianceFormSubmit> = this.formValue$.pipe(
    // Do not request another quote immediately if form loads in valid state
    skip(1),
    filter(() => (this.form?.valid && this.form?.enabled) ?? false),
    map((applianceDetailsFormValue: ApplianceFormValue) => ({
      ...applianceDetailsFormValue,
      applianceCode: applianceDetailsFormValue[ApplianceFormField.Appliance],
      brandCode: applianceDetailsFormValue[ApplianceFormField.Brand],
    }))
  );

  readonly validatedSubmit$: Observable<ApplianceFormSubmit> = this.select(
    this.applianceCode$.pipe(filterNullUndefined()),
    this.brandCode$.pipe(filterNullUndefined()),
    this.submittedForm$.pipe(filterNullUndefined()),
    (applianceCode, brandCode, submittedForm) => ({
      ...submittedForm,
      applianceCode,
      brandCode,
    })
  );

  /**
   * Combined Selectors
   */

  private fixedValueControls$: Observable<
    ApplianceFormField[]
  > = this.applianceIsHeating$.pipe(
    withLatestFrom(this.config$),
    map(([applianceIsHeating, config]) => {
      const fixedValueControls = Object.keys(config?.fixedValues ?? {});

      if (applianceIsHeating) {
        fixedValueControls.push(ApplianceFormField.PurchasePrice);
      }
      return fixedValueControls as ApplianceFormField[];
    }),
    distinctUntilChangedDeep()
  );

  private preventInWarrantySales$: Observable<boolean> = this.config$.pipe(
    switchMap((config?: ApplianceDetailsConfig) =>
      this.appliance$.pipe(
        map((appliance?: Appliance) => {
          const warrantyConfig = config?.showInWarrantyStopMsg;
          const applianceCategories = warrantyConfig?.applianceCategories ?? {};
          const category = appliance?.category ?? '';
          return (
            applianceCategories[category] ?? warrantyConfig?.default ?? false
          );
        }),
        distinctUntilChanged()
      )
    )
  );

  private inBrandWarranty$: Observable<boolean> = combineLatest([
    this.purchaseDate$,
    this.brand$,
    this.preventInWarrantySales$,
  ]).pipe(
    map(([purchaseDate, brand, preventInWarrantySales]) => {
      const date = new Date();
      const brandWarranty = brand?.warranty ?? 0;
      const applianceAge =
        (date.getFullYear() - purchaseDate?.year) * 12 +
        (date.getMonth() + 1 - purchaseDate?.month);

      return preventInWarrantySales && applianceAge < brandWarranty;
    }),
    distinctUntilChanged()
  );

  private inExtendedWarranty$: Observable<boolean> = this.form$.pipe(
    pluckControl(ApplianceFormField.GuaranteeDuration),
    controlHasError('max'),
    startWith(false),
    distinctUntilChanged()
  );

  private inWarranty$: Observable<boolean> = combineLatest([
    this.underGuarantee$,
    this.preventInWarrantySales$,
  ]).pipe(
    map(
      ([underGuarantee, preventInWarrantySales]) =>
        underGuarantee && preventInWarrantySales
    ),
    distinctUntilChanged()
  );

  /**
   * Updater Sources
   */
  private formBuilderConfigUpdater$ = combineLatest([
    this.cmsFormData$,
    this.config$,
    this.prefilledForm$,
  ]).pipe(
    debounceTime(0),
    filter(([cmsData]) => !!cmsData?.length),
    first(),
    map(([cmsFormData, config, prefilledForm]) =>
      this.applianceDetailsFormConfig.getFormbuilderConfig(
        cmsFormData,
        this.appliancesOptions$,
        this.brandsOptions$,
        this.purchasePriceOptions$,
        prefilledForm,
        this.loadingBrandSelection$,
        config
      )
    )
  );

  private formUpdater$ = this.formBuilderConfig$.pipe(
    first(),
    map((formBuilderConfig: FieldDef[]) =>
      this.dynamicFormBuilder.generateFormGroup(formBuilderConfig, {
        asyncValidators: [
          this.applianceDetailsFormConfig.heatingApplianceUnderGuaranteeValidator(
            this.applianceIsHeating$
          ),
        ],
      })
    )
  );

  private formControlsToHideUpdater$: Observable<
    ApplianceFormField[]
  > = combineLatest([
    this.formValue$,
    this.fixedValueControls$,
    this.applianceIsHeating$,
    this.inBrandWarranty$,
    this.inExtendedWarranty$,
    this.inWarranty$,
  ]).pipe(map((args) => revealApplianceDetailsFormProgressively(...args)));

  // ***** Updaters *****
  private readonly formUpdater = this.updater(
    (state: ApplianceFormDataState, form: FormGroup) => ({
      ...state,
      form,
    })
  )(this.formUpdater$);

  private readonly formBuilderConfigUpdater = this.updater(
    (state: ApplianceFormDataState, formBuilderConfig: FieldDef[]) => ({
      ...state,
      formBuilderConfig,
    })
  )(this.formBuilderConfigUpdater$);

  private readonly formControlsToHideUpdater = this.updater(
    (
      state: ApplianceFormDataState,
      formControlsToHide: ApplianceFormField[]
    ) => ({
      ...state,
      formControlsToHide,
    })
  )(this.formControlsToHideUpdater$);

  private readonly monthYearDateUpdater = this.updater(
    (
      state: ApplianceFormDataState,
      [underGuarantee, brand]: [boolean, Brand | undefined]
    ) => ({
      ...state,
      monthYearDateUpdater: underGuarantee
        ? undefined
        : {
            defaultMonth: new Date().getMonth() + 1,
            minimumAgeInMonths: brand?.warranty,
          },
    })
  )(combineLatest([this.underGuarantee$, this.brand$]));

  readonly setAppliances = this.updater(
    (
      state: ApplianceFormDataState,
      appliances: Appliance[] | null | undefined
    ) => ({
      ...state,
      appliances: appliances ?? [],
    })
  );

  readonly setBrands = this.updater(
    (state: ApplianceFormDataState, brands: Brand[] | null | undefined) => ({
      ...state,
      brands: brands ?? [],
    })
  );

  readonly setBrandsLoaded = this.updater(
    (state: ApplianceFormDataState, loadingBrandSelection: boolean) => ({
      ...state,
      loadingBrandSelection,
    })
  );

  readonly setCmsFormData = this.updater(
    (state: ApplianceFormDataState, cmsFormData: ApplianceFormFields) => ({
      ...state,
      cmsFormData,
    })
  );

  readonly setConfig = this.updater(
    (
      state: ApplianceFormDataState,
      config: ApplianceDetailsConfig | null | undefined
    ) => ({
      ...state,
      config: config ?? undefined,
    })
  );

  readonly setPrefilledFormData = this.updater(
    (
      state: ApplianceFormDataState,
      prefilledForm: ApplianceFormValue | null | undefined
    ) => ({
      ...state,
      prefilledForm: prefilledForm ?? undefined,
    })
  );

  readonly updateSubmittedForm = this.updater(
    (state: ApplianceFormDataState, submittedForm: ApplianceFormValue) => ({
      ...state,
      submittedForm,
    })
  );

  /**
   * Effects
   */
  readonly defaultPurchasePriceControlValue$ = this.effect(
    (defaultPurchasePriceControl$: Observable<boolean>) =>
      defaultPurchasePriceControl$.pipe(
        filter(Boolean),
        tap(() =>
          this.form
            ?.get(ApplianceFormField.PurchasePrice)
            ?.setValue(DEFAULT_PURCHASE_PRICE)
        )
      )
  )(
    combineLatest([this.purchasePrice$, this.fixedValueControls$]).pipe(
      map(
        ([purchasePriceValue, fixedValueControls]) =>
          purchasePriceValue?.toString() !==
            DEFAULT_PURCHASE_PRICE?.toString() &&
          fixedValueControls.includes(ApplianceFormField.PurchasePrice)
      )
    )
  );

  readonly enableBrandControl = this.effect((enabled$: Observable<boolean>) =>
    this.form$.pipe(
      pluckControl(ApplianceFormField.Brand),
      switchMap((control) => enabled$.pipe(enableControl(control)))
    )
  )(
    combineLatest([this.brands$, this.applianceCode$]).pipe(
      map(([brands, applianceCode]) => !!brands.length && !!applianceCode)
    )
  );

  readonly enableGuaranteeDurationControl = this.effect(
    (enabled$: Observable<boolean>) =>
      this.form$.pipe(
        pluckControl(ApplianceFormField.GuaranteeDuration),
        switchMap((control) => enabled$.pipe(enableControl(control)))
      )
  )(
    combineLatest([this.underGuarantee$, this.applianceIsHeating$]).pipe(
      map(([underGuarantee, isHeating]) => underGuarantee && !isHeating)
    )
  );

  readonly resetControlsOnUnderGuaranteeChanges = this.effect(
    (underGuarantee$: Observable<boolean>) =>
      underGuarantee$.pipe(
        mapToLatestFrom(this.form$),
        resetControls(
          ApplianceFormField.GuaranteeDuration,
          ApplianceFormField.PurchaseDate,
          ApplianceFormField.PurchasePrice
        )
      )
  )(this.underGuarantee$.pipe(skip(1)));

  readonly resetControlsOnApplianceNotWorkingChanges = this.effect(
    (applianceNotWorking$: Observable<boolean>) =>
      applianceNotWorking$.pipe(
        filter(Boolean),
        mapToLatestFrom(this.form$),
        resetControls(
          ApplianceFormField.GuaranteeDuration,
          ApplianceFormField.PurchaseDate,
          ApplianceFormField.PurchasePrice,
          ApplianceFormField.UnderGuarantee
        )
      )
  )(this.applianceNotWorking$.pipe(skip(1)));

  readonly resetFormOnApplianceChange = this.effect(
    (applianceCode$: Observable<string | undefined>) =>
      applianceCode$.pipe(
        mapToLatestFrom(this.form$),
        resetControls(
          ApplianceFormField.Brand,
          ApplianceFormField.GoodWorkingOrder,
          ApplianceFormField.GuaranteeDuration,
          ApplianceFormField.PurchaseDate,
          ApplianceFormField.PurchasePrice,
          ApplianceFormField.UnderGuarantee
        )
      )
  )(this.applianceCode$.pipe(skip(1)));

  /**
   * VM
   */
  readonly vm$: Observable<ViewModel> = this.select(
    this.appliance$,
    this.applianceNotWorking$,
    this.formBuilderConfig$,
    this.formControlsToHide$,
    this.form$,
    this.inBrandWarranty$,
    this.inExtendedWarranty$,
    this.inWarranty$,
    this.monthYearDateUpdater$,
    (
      appliance,
      applianceNotWorking,
      formBuilderConfig,
      formControlsToHide,
      form,
      inBrandWarranty,
      inExtendedWarranty,
      inWarranty,
      monthYearDateUpdater
    ) => ({
      appliance,
      applianceNotWorking,
      formBuilderConfig,
      formControlsToHide,
      form,
      inBrandWarranty,
      inExtendedWarranty,
      inWarranty,
      monthYearDateUpdater,
    })
  );

  constructor(
    private applianceDetailsFormConfig: ApplianceDetailsFormConfigService,
    private dynamicFormBuilder: DynamicFormbuilderService
  ) {
    super({
      appliances: [],
      brands: [],
      cmsFormData: [],
      config: undefined,
      form: undefined,
      formBuilderConfig: undefined,
      formControlsToHide: [],
      formValue: {} as ApplianceFormValue,
      loadingBrandSelection: false,
      prefilledForm: undefined,
      submittedForm: undefined,
    });
  }

  get form(): FormGroup | undefined {
    return this.get((state: ApplianceFormDataState) => state.form);
  }
}
