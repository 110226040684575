import { DOCUMENT } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, Component, Injector, Type } from '@angular/core';
import {
  FeatureConfigFacade,
  featureConfigInitializer,
} from '@common/data-access-feature-config';
import {
  AddCorrelationIdHeaderInterceptor,
  cmsDataUrlToken,
} from '@common/data-access-shared';
import {
  BuildConfigService,
  CmsConfigAdapter,
  CONFIG_ADAPTER,
  cookieProInitializer,
  GA_TAGS,
  GOOGLE_TAG_GLOBAL_PROPERTIES,
  RequestInterceptorService,
  RequestSourceConfigAdapterService,
  WorldpayConfigAdapter,
} from '@common/util-foundation';
import { CmsDataUrl, GoogleTagConfig } from '@common/util-models';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';

import { AppProvidersConfigToken } from './app-providers-config.token';
import { cmsConfigToken } from './cms-config.token';

const recaptchaSiteKeyFactory = (buildConfigService: BuildConfigService) => {
  return buildConfigService.config.recaptchaSiteKey;
};

export type ComponentMapping = {
  [key: string]: Type<Component>;
};

export type AppProvidersConfig = {
  COMPONENT_MAPPING: ComponentMapping;
  gaTagsConfig: GoogleTagConfig;
  cmsDataUrl: CmsDataUrl;
  googleTagGlobalProps?: Record<string, string>;
};

const cmsConfigFactory = (
  buildConfigService: BuildConfigService,
  injector: Injector
) => {
  const { COMPONENT_MAPPING } = injector.get(AppProvidersConfigToken);
  return buildConfigService.getCmsConfig(COMPONENT_MAPPING);
};

const cmsDataUrlTokenFactory = (injector: Injector) => {
  return injector.get(AppProvidersConfigToken).cmsDataUrl;
};

const gaTagsTokenFactory = (injector: Injector) => {
  return injector.get(AppProvidersConfigToken).gaTagsConfig;
};

const googleTagGlobalPropsTokenFactory = (injector: Injector) => {
  return injector.get(AppProvidersConfigToken).googleTagGlobalProps;
};

export const PROVIDERS = [
  {
    provide: cmsDataUrlToken,
    useFactory: cmsDataUrlTokenFactory,
    deps: [Injector],
  },
  {
    provide: cmsConfigToken,
    useFactory: cmsConfigFactory,
    deps: [BuildConfigService, Injector],
  },
  {
    provide: GA_TAGS,
    useFactory: gaTagsTokenFactory,
    deps: [Injector],
  },
  {
    provide: GOOGLE_TAG_GLOBAL_PROPERTIES,
    useFactory: googleTagGlobalPropsTokenFactory,
    deps: [Injector],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: cookieProInitializer,
    multi: true,
    deps: [DOCUMENT, BuildConfigService],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: featureConfigInitializer,
    multi: true,
    deps: [FeatureConfigFacade],
  },
  {
    provide: RECAPTCHA_V3_SITE_KEY,
    useFactory: recaptchaSiteKeyFactory,
    deps: [BuildConfigService],
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RequestInterceptorService,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AddCorrelationIdHeaderInterceptor,
    multi: true,
  },
  {
    provide: CONFIG_ADAPTER,
    useClass: WorldpayConfigAdapter,
    multi: true,
  },
  {
    provide: CONFIG_ADAPTER,
    useClass: RequestSourceConfigAdapterService,
    multi: true,
  },
  {
    provide: CONFIG_ADAPTER,
    useClass: CmsConfigAdapter,
    multi: true,
  },
];
